<template>
  <b-modal
    ref="modal"
    v-model="onControl"
    centered
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    title="New Payment Method"
    @hidden="close"
  >
    <validation-observer ref="form">
      <validation-provider
          v-slot="{ errors }"
          name="name"
          rules="required"
      >
      <b-form-group
          label="Name"
          label-for="input-1"
      >
        <b-form-input
            id="input-1"
            v-model="name"
        />
        <span
            v-if="errors[0]"
            class="text-danger"
        >Name {{ errors[0] }}</span>
      </b-form-group>
      </validation-provider>
    </validation-observer>
    <b-form-checkbox v-model="isCreditCard">This is a credit card</b-form-checkbox>
    <template #modal-footer>
      <b-button variant="primary" @click="savePaymentMethod">
        <b-spinner
          v-if="spinnerOn"
          small
        />
        SAVE
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import CompaniesService from '@/views/management/views/companies-old/companies.service'
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      onControl: false,
      name: '',
      isCreditCard: false,
      idCompany: '',
      spinnerOn: false,
    }
  },
  async created() {
    this.idCompany = this.$route.params.id
    this.onControl = true
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async savePaymentMethod() {
      try {
        this.spinnerOn = true
        const result = await this.$refs.form.validate()
        if (result) {
          const data = await CompaniesService.savePaymentMethod({
            name: this.name,
            is_credit_card: this.isCreditCard,
            created_by: this.currentUser.user_id,
            company_id: this.idCompany,
          })
          if (data.status === 200) {
            this.close()
            this.spinnerOn = false
            this.$emit('reload')
            this.showSuccessSwal()
          }
        }
      } catch (e) {
        this.spinnerOn = false
        this.showErrorSwal(e)
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
}
</script>

<style scoped>

</style>
