<template>
  <b-card class="border-top-primary border-3 border-table-radius px-0">
  <div>
    <div class="d-flex justify-content-between mb-1">
      <div />
      <div>
        <b-button
          variant="success"
          @click="openListVendorsTranModal"
        >
          <feather-icon
            icon="PlusIcon"
            size="15"
            class="mr-50 text-white"
          />VENDORS
        </b-button>
        <b-dropdown
          class="ml-1"
          no-caret
          right
          text="+ New"
          variant="success"
        >
          <template #button-content>
            <feather-icon
              icon="PlusIcon"
              size="15"
              class="mr-50 text-white"
            />NEW
          </template>
          <b-dropdown-item @click="openExpenseModal">
            Expense
          </b-dropdown-item>
          <b-dropdown-item @click="checkModalCont = true">
            Check
          </b-dropdown-item>
          <b-dropdown-item @click="billModalCont = true">
            Bill
          </b-dropdown-item>
          <b-dropdown-item @click="bankModalCont = true">Bank Transaction</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <b-nav
      pills
      class="m-0"
    >
      <b-nav-item
        exact
        :active="tab === 1"
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
        @click="tab = 1"
      >EXPENSES</b-nav-item>
      <b-nav-item
        exact
        :active="tab === 2"
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
        @click="tab = 2"
      >BANK TRANSFERS</b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <expenses-table
        v-if="tab===1"
        :key="key"
      />
      <bank-transfers-table v-if="tab===2" />
    </b-card>
    <list-vendors-modal
      v-if="openListVendorsModalController"
      @close="closeListVendorsTranModal"
    />
    <create-expense-transaction-modal
      v-if="expenseModalCont"
      @close="closeExpenseModal"
      @reload="reloadEvent"
    />
    <create-check-transaction-modal
      v-if="checkModalCont"
      @close="closeCheckModal"
      @reload="reloadEvent"
    />
    <create-bill-transaction-modal
      v-if="billModalCont"
      @close="billModalCont = false"
      @reload="reloadEvent"
    />
    <create-bank-transaction-modal
      v-if="bankModalCont"
      @close="bankModalCont = false"
      @reload="reloadEvent"
    />
  </div>
  </b-card>
</template>

<script>
import ExpensesTable from '@/views/management/views/companies-old/dashboard/expenses-module/tables/ExpensesTable'
import ListVendorsModal from '@/views/management/views/companies-old/dashboard/expenses-module/modals/ListVendorsModal'
import BankTransfersTable from '@/views/management/views/companies-old/dashboard/expenses-module/tables/BankTransfersTable'
import CreateExpenseTransactionModal from '@/views/management/views/companies-old/dashboard/expenses-module/modals/CreateExpenseTransactionModal'
import CreateCheckTransactionModal from '@/views/management/views/companies-old/dashboard/expenses-module/modals/CreateCheckTransactionModal'
import CreateBillTransactionModal from '@/views/management/views/companies-old/dashboard/expenses-module/modals/CreateBillTransactionModal'
import CreateBankTransactionModal from '@/views/management/views/companies-old/dashboard/expenses-module/modals/CreateBankTransactionModal'

export default {
  components: {
    ExpensesTable,
    ListVendorsModal,
    BankTransfersTable,
    CreateExpenseTransactionModal,
    CreateCheckTransactionModal,
    CreateBillTransactionModal,
    CreateBankTransactionModal,
  },
  data() {
    return {
      openListVendorsModalController: false,
      expenseModalCont: false,
      checkModalCont: false,
      billModalCont: false,
      bankModalCont: false,
      tab: 1,
      key: 1,
    }
  },
  methods: {
    closeListVendorsTranModal() {
      this.openListVendorsModalController = false
    },
    openListVendorsTranModal() {
      this.openListVendorsModalController = true
    },
    closeExpenseModal() {
      this.expenseModalCont = false
    },
    openExpenseModal() {
      this.expenseModalCont = true
    },
    closeCheckModal() {
      this.checkModalCont = false
    },
    reloadEvent() {
      this.key++
    },
  },
}
</script>

<style scoped>

</style>
