<template>
  <b-modal
    id="modal-closing"
    ref="modal"
    v-model="onControl"
    :header-bg-variant=" isDarkSkin?'dark' : 'light'"
    modal-class="tree-modal modal-primary transaction-modal"
    body-class="p-0"
    title-tag="h3"
    title-class="h3 text-dark font-weight-bolder"
    footer-bg-variant="dark"
    scrollable
    hide-header
    @hidden="close"
  >
    <validation-observer ref="form">
      <b-row
        style="width: 100vw !important"
        class="m-0 px-1 pt-2"
        :class="isDarkSkin? 'bg-dark' : 'bg-light'"
      >
        <div
          style="width: 100% !important"
          class="d-flex justify-content-between"
        >
          <h2 class="d-inline text-dark font-weight-bolder mb-2 ml-1">
            BANK TRANSFER {{ bankTransfer.doc_no ? "no." + bankTransfer.doc_no : "" }}
          </h2>
          <div
            style="background: white !important;border: none; width: 30px !important; height: 30px !important;"
            class=" bg-white py-0 cursor-pointer rounded close-but"
            @click="close()"
          >
            <feather-icon
              class="text-primary"
              style="margin: 5px !important"
              icon="XIcon"
              size="18"
            />
          </div>
        </div>
        <b-row
          class="m-0 mt-1 mb-1"
          style="width: 100% !important"
        >
          <b-col md="12">
            <b-form-checkbox v-model="bankTransfer.is_intercompany_transfer">
              This is an inter-company transfer
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row
          class="m-0 mt-1 mb-1"
          style="width: 100% !important"
        >
          <b-col md="9">
            <b-row>
              <b-col md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="or_account"
                  rules="required"
                >
                  <span class="d-flex justify-content-between mb-half">
                    <span>Origin account&nbsp;<span
                      v-b-tooltip.hover.top="'Select the account you want to transfer funds from'"
                      class="cursor-pointer"
                    >(?)</span></span>
                    <feather-icon
                      v-if="!checkAddButton && !isLoading"
                      icon="PlusCircleIcon"
                      size="22"
                      class="text-primary cursor-pointer"
                      @click="bankAccModalController=true"
                    />
                  </span>
                  <v-select
                    v-model="bankTransfer.payment_account"
                    class="bg-white"
                    placeholder="Select option"
                    :reduce="bank_name => bank_name.id"
                    :options="bankAccounts"
                    label="bank_name"
                    :disabled="bankTransfer.is_intercompany_transfer"
                  >
                    <template
                      #selected-option-container="{ option}"
                    >
                      <div class="vs__selected">
                        <span v-if="bankExistsOrigin">
                          {{ option.bank_name }}
                        </span>
                        <span v-else>This bank account was deleted: {{ option.bank_name }}</span>
                      </div>
                    </template>
                    <!--                    <template #selected-option="{bank_name, currency_text}">-->
                    <!--                      {{ bank_name }} - {{ currency_text }}-->
                    <!--                    </template>-->
                    <template #option="{bank_name, currency_text}">
                      {{ bank_name }} - {{ currency_text }}
                    </template>
                  </v-select>
                  <span
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    Origin account {{ errors[0] }}</span>
                </validation-provider>
              </b-col>
              <b-col
                v-if="bankTransfer.is_intercompany_transfer"
                md="4"
              >
                <span class="d-flex justify-content-between mb-half">
                  <span>Destination company &nbsp;<span
                    v-b-tooltip.hover.top="'Select the account you want to transfer funds to'"
                    class="cursor-pointer"
                  >(?)</span></span>
                </span>
                <v-select
                  v-model="bankTransfer.destination_company_id"
                  class="bg-white"
                  :reduce="business_name => business_name.id"
                  label="business_name"
                  :disabled="isConfirmed"
                  :options="otherCompanies"
                />
              </b-col>
              <b-col
                v-if="!bankTransfer.is_intercompany_transfer"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="or_dest"
                  rules="required"
                >
                  <span class="d-flex justify-content-between mb-half">
                    <span>Destination account &nbsp;<span
                      v-b-tooltip.hover.top="'Select the account you want to transfer funds to'"
                      class="cursor-pointer"
                    >(?)</span></span>
                    <feather-icon
                      v-if="!bankTransfer.is_intercompany_transfer && (!checkAddButton && !isLoading)"
                      icon="PlusCircleIcon"
                      size="22"
                      class="text-primary cursor-pointer"
                      @click="bankAccModalController=true"
                    />
                  </span>
                  <v-select
                    v-model="bankTransfer.bank_account_dest_id"
                    class="bg-white"
                    :options="bankAccountsDestination"
                    :reduce="bank_name => bank_name.id"
                    label="bank_name"
                    :disabled="isConfirmed"
                  >
                    <template #option="{bank_name, currency_text}">
                      {{ bank_name }} - {{ currency_text }}
                    </template>
                    <template
                        #selected-option-container="{ option}"
                    >
                      <div class="vs__selected">
                        <span v-if="bankExistsDest">
                          {{ option.bank_name }}
                        </span>
                        <span v-else>This bank account was deleted: {{ option.bank_name }}</span>
                      </div>
                    </template>
<!--                    <template #selected-option="{bank_name, currency_text}">-->
<!--                      {{ bank_name }} - {{ currency_text }}-->
<!--                    </template>-->
                  </v-select>
                  <span
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    Destination account {{ errors[0] }}</span>
                </validation-provider>
              </b-col>
              <b-col
                v-if="!bankTransfer.is_intercompany_transfer"
                md="2"
              >
                <b-form-group label="Exchange Rate">
                  <money
                    v-model="bankTransfer.exchange"
                    class="input-money-style form-control"
                    v-bind="moneyExchange"
                  />
                </b-form-group>
              </b-col>
              <b-col md="1">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="doc_no"
                  rules="required"
                >
                  <b-form-group label="Ref no.">
                    <b-form-input
                      v-model="bankTransfer.doc_no"
                      :disabled="isConfirmed"
                    />
                    <span
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      Ref no. {{ errors[0] }}</span>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4" />
              <b-col
                v-if="bankTransfer.is_intercompany_transfer"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="or_dest"
                  rules="required"
                >
                  <span class="d-flex justify-content-between mb-half">
                    <span>Destination account &nbsp;<span
                      v-b-tooltip.hover.top="'Select the account you want to transfer funds to'"
                      class="cursor-pointer"
                    >(?)</span></span>
                    <feather-icon
                      v-if="!bankTransfer.is_intercompany_transfer && (!checkAddButton && !isLoading)"
                      icon="PlusCircleIcon"
                      size="22"
                      class="text-primary cursor-pointer"
                      @click="bankAccModalController=true"
                    />
                  </span>
                  <v-select
                    v-model="bankTransfer.bank_account_dest_id"
                    class="bg-white"
                    :options="bankAccountsDestination"
                    :reduce="bank_name => bank_name.id"
                    label="bank_name"
                    :disabled="isConfirmed"
                  >
                    <template #option="{bank_name, currency_text}">
                      {{ bank_name }} - {{ currency_text }}
                    </template>
                    <template
                        #selected-option-container="{ option}"
                    >
                      <div class="vs__selected">
                        <span v-if="bankExistsDest">
                          {{ option.bank_name }}
                        </span>
                        <span v-else>This bank account was deleted: {{ option.bank_name }}</span>
                      </div>
                    </template>
<!--                    <template #selected-option="{bank_name, currency_text}">-->
<!--                      {{ bank_name }} - {{ currency_text }}-->
<!--                    </template>-->
                  </v-select>
                  <span
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    Destination account {{ errors[0] }}</span>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row v-if="currentBreakPoint ==='lg' || currentBreakPoint ==='md'">
              <b-col
                cols="4"
                class="d-flex"
              >
                <span>
                  <span class=" mt-1 font-weight-bolder">Origin acc balance</span>
                  <h3>
                    <money
                      :value="originAccountBalance"
                      class="p-0 border-0 font-weight-bolder text-secondary text-right"
                      style="font-size: 20px; background: transparent"
                      v-bind="moneyOrigin"
                      disabled
                    />
                  </h3>
                </span>
              </b-col>
              <b-col
                cols="4"
                class="d-flex"
              >
                <span>
                  <span class="mt-1 font-weight-bolder">Destination acc balance  &nbsp; &nbsp; </span>
                  <h3>
                    <money
                      :value="destinationAccountBalance"
                      class="p-0 border-0 font-weight-bolder text-secondary text-right"
                      style="font-size: 20px; background: transparent"
                      v-bind="moneyDestination"
                      disabled
                    />
                  </h3>
                </span>
              </b-col>
              <b-col md="3">
                <span class="d-flex justify-content-between mb-half">
                  <span>Transfer date</span>
                </span>
                <b-form-datepicker
                  v-model="bankTransfer.payment_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="en"
                  :disabled="isConfirmed"
                />
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col
                cols="4"
                class="d-flex"
              >
                <span class="d-flex align-items-center">
                  <span class="mt-1 font-weight-bolder">Origin acc balance  &nbsp; &nbsp; </span>
                  <span>
                    <h3>
                      <money
                        :value="originAccountBalance"
                        class="p-0 border-0 ml-1 font-weight-bolder text-secondary text-right"
                        style="font-size: 20px; background: transparent"
                        v-bind="moneyOrigin"
                        disabled
                      />
                    </h3>
                  </span>
                </span>
              </b-col>
              <b-col
                cols="4"
                class="d-flex"
              >
                <span class="d-flex align-items-center">
                  <span class="mt-1 font-weight-bolder">Destination acc balance  &nbsp; &nbsp; </span>
                  <span>
                    <h3>
                      <money
                        :value="destinationAccountBalance"
                        class="p-0 border-0 ml-1 font-weight-bolder text-secondary text-right"
                        style="font-size: 20px; background: transparent"
                        v-bind="moneyDestination"
                        disabled
                      />
                    </h3>
                  </span>
                </span>
              </b-col>
              <b-col md="3">
                <span class="d-flex justify-content-between mb-half">
                  <span>Transfer date</span>
                </span>
                <b-form-datepicker
                  v-model="bankTransfer.payment_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="en"
                  :disabled="isConfirmed"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="3"
            class="mt-1"
          >
            <validation-provider
              v-slot="{ errors, valid }"
              name="amount"
              rules="validate-amount"
            >
              <div class="text-right mb-1 font-weight-bolder">
                Amount
              </div>
              <money
                v-if="moneyOrigin"
                v-model="bankTransfer.total"
                class="input-money-style form-control input-money-specific text-right"
                style="height: 40px;font-size: 30pt; width: 100%;"
                v-bind="moneyOrigin"
              />
              <span
                v-if="errors[0] && validator"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-col>
        </b-row>
      </b-row>
      <b-row
        class="mt-2 ml-1 d-flex justify-content-between"
        style="width: 100%;height: 80px"
      >
        <b-col
          xl="5"
          lg="5"
          md="5"
          sm="12"
        >
          <b-form-group
            label="Memo"
          >
            <b-form-textarea
              v-model="bankTransfer.memo"
              style="width: 100%; height: 275px"
              :disabled="isConfirmed"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isConfirmed"
          xl="7"
          lg="7"
          md="7"
          sm="12"
        >
          <b-col md="12">
            <b-row>
              <b-col md="6">
                <div class="mb-half">
                  Attachments
                </div>
              </b-col>
              <b-col md="6">
                <div class="mb-half">
                  Maximum size: 20MB
                </div>
              </b-col>
            </b-row>
            <drag-and-drop
              ref="file-manager"
              v-model="files"
              source="bankTransaction"
              :files-array="files"
              :files-to-show="bankTransfer.files"
              @delete-file="deleteFile"
            />
          </b-col>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{cancel}">
      <div
        style="width: 100%;"
        class="d-flex justify-content-between"
      >
        <div>
          <b-button
            class="mr-1"
            @click="cancel()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="bankTransfer.status == null"
            @click="clearData"
          >
            Clear
          </b-button>
        </div>
        <b-dropdown
          dropup
          split
          variant="primary"
          text="Save and Close"
          @click="upload"
        >
          <b-dropdown-item @click="upload(1)">
            Save and new
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
    <create-bank-account
      v-if="bankAccModalController"
      :edit="false"
      @close="bankAccModalController = false"
      @refresh="getAllBankAccounts"
    />
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import dragAndDrop from '@/views/commons/utilities/DragAndDrop'
import CreateBankAccount from '@/views/management/views/companies-old/dashboard/bank-accounts-module/modals/CreateBankAccount'
import CompaniesService from '@/views/management/views/companies-old/companies.service'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  components: {
    vSelect,
    CreateBankAccount,
    dragAndDrop,
  },
  props: {
    transactionId: null,
  },
  data() {
    return {
      onControl: false,
      bankExistsOrigin: true,
      bankExistsDest: true,
      checkAddButton: false,
      isLoading: false,
      idDrag: 1,
      files: [],
      vendorModalController: false,
      bankAccModalController: false,
      vendors: [],
      bankAccounts: [],
      validator: false,
      prueba: 0,
      bankAccountsDestination: [],
      idCompany: '',
      moneyExchange: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        precision: 2,
        masked: false,
      },
      otherCompanies: [],
      currencies: [
        {
          id: 1,
          label: 'S/',
        },
        {
          id: 2,
          label: '$',
        },
      ],
      bankTransfer: {
        transaction_id: null,
        company_id: '',
        destination_company_id: null,
        payee: null,
        payment_account: null,
        bank_account_dest_id: null,
        payment_date: null,
        due_date: null,
        payment_method: null,
        doc_no: null,
        term_id: null,
        memo: null,
        mailling_address: null,
        exchange: 0,
        type: 4,
        status: null,
        currency: null,
        previous_total: null,
        total: 0,
        is_intercompany_transfer: false,
        files: null,
        detail: [
          {
            category: null,
            description: null,
            amount: 0,
            id: null,
            identifier: null,
          },
          {
            category: null,
            description: null,
            amount: 0,
            id: null,
            identifier: null,
          },
        ],
      },
      invoice: {
        company_id: '',
        customer_id: null,
        customer_email: '',
        term_id: '',
        due_date: '',
        billing_address: '',
        balance_due: '',
        message_on_invoice: '',
        message_on_statement: '',
        type: 2,
        payment_account: '',
        income_date: '',
        exchange: '',
        income_id: null,
        doc_no: '',
        bank_account_orig_id: '',
        bank_account_dest_id: '',
        currency: '',
        files: null,
        previous_total: '',
        is_intercompany_transfer: '',
        detail: [
          {
            service: null,
            description: null,
            quantity: 1,
            rate: 0,
            amount: null,
            id: null,
            identifier: null,
          },
          {
            service: null,
            description: null,
            quantity: 1,
            rate: 0,
            amount: null,
            id: null,
            identifier: null,
          },
        ],
      },
      deletedFiles: [],
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      currentBreakPoint: 'app/currentBreakPoint',
    }),
    destinationAccountCurrency() {
      for (let i = 0; i < this.bankAccountsDestination.length; i++) {
        if (this.bankAccountsDestination[i].id == this.bankTransfer.bank_account_dest_id) {
          return this.bankAccountsDestination[i].currency_text
        }
      }
    },
    destinationAccountCurrencyNumber() {
      for (let i = 0; i < this.bankAccountsDestination.length; i++) {
        if (this.bankAccountsDestination[i].id === this.bankTransfer.bank_account_dest_id) {
          return this.bankAccountsDestination[i].currency
        }
      }
    },
    originAccountCurrency() {
      for (let i = 0; i < this.bankAccounts.length; i++) {
        if (this.bankAccounts[i].id == this.bankTransfer.payment_account) {
          return this.bankAccounts[i].currency_text
        }
      }
    },
    isConfirmed() {
      return this.bankTransfer.status === 2
    },
    moneyDestination() {
      return {
        decimal: '.',
        thousands: ',',
        prefix: this.destinationAccountCurrency,
        precision: 2,
        masked: false,
      }
    },
    moneyOrigin() {
      return {
        decimal: '.',
        thousands: ',',
        prefix: this.originAccountCurrency,
        precision: 2,
        masked: false,
      }
    },
    originAccountBalance() {
      for (let i = 0; i < this.bankAccounts.length; i++) {
        if (this.bankAccounts[i].id == this.bankTransfer.payment_account) {
          return this.calculateNewBalance(
            this.bankAccounts[i].balance,
            this.bankAccounts[i].total_incomes,
            this.bankAccounts[i].total_expenses,
          )
        }
      }
    },
    destinationAccountBalance() {
      for (let i = 0; i < this.bankAccountsDestination.length; i++) {
        if (this.bankAccountsDestination[i].id == this.bankTransfer.bank_account_dest_id) {
          return this.calculateNewBalance(
            this.bankAccountsDestination[i].balance,
            this.bankAccountsDestination[i].total_incomes,
            this.bankAccountsDestination[i].total_expenses,
          )
        }
      }
    },
  },
  watch: {
    'bankTransfer.payment_account': function (newVal, oldVal) {
      for (let i = 0; i < this.bankAccounts.length; i++) {
        if (this.bankAccounts[i].id == newVal) {
          this.bankTransfer.currency = this.bankAccounts[i].currency
          break
        }
      }
      if ((this.bankTransfer.payment_account !== null && this.bankTransfer.bank_account_dest_id !== null)) {
        if (this.bankTransfer.payment_account == this.bankTransfer.bank_account_dest_id) {
          this.showErrorSwal('Origin account can\'t be the same as the destination account', 'Incorrect account')
          this.bankTransfer.payment_account = null
        }
      }
    },
    'bankTransfer.bank_account_dest_id': function (newVal, oldVal) {
      if ((this.bankTransfer.payment_account !== null && this.bankTransfer.bank_account_dest_id !== null)) {
        if (this.bankTransfer.payment_account == this.bankTransfer.bank_account_dest_id) {
          this.showErrorSwal('Destination account can\'t be the same as the origin account', 'Incorrect account')
          this.bankTransfer.bank_account_dest_id = null
        }
      }

      if (this.bankTransfer.is_intercompany_transfer && this.destinationAccountCurrencyNumber == 1) {
        this.showErrorSwal("Destination account can't be in soles when it's a transfer between companies!", 'Incorrect account')
        this.bankTransfer.bank_account_dest_id = null
      }
    },
    'bankTransfer.is_intercompany_transfer': function (newVal) {
      this.bankTransfer.bank_account_dest_id = null
      if (newVal) {
        for (let i = 0; i < this.bankAccounts.length; i++) {
          if (this.bankAccounts[i].currency == 2) {
            this.bankTransfer.payment_account = this.bankAccounts[i].id
            break
          }
        }
      } else {
        this.getBankAccounts()
      }
    },
    'bankTransfer.destination_company_id': function (newVal) {
      this.bankTransfer.bank_account_dest_id = null
      if (this.bankTransfer.is_intercompany_transfer) {
        this.getBankAccountsDestination()
      } else {
        this.getBankAccounts()
      }
    },
    'bankTransfer.total': function (newVal, oldVal) {
      if (newVal > this.originAccountBalance && this.originAccountBalance !== null) {
        this.showErrorSwal(`Total must be less than the balance of the origin account    (${this.originAccountCurrency} ${this.originAccountBalance})`, 'Incorrect amount')
        this.bankTransfer.total = 0.00000
      }
    },
    originAccountBalance(newVal, oldVal) {
      if (newVal < this.bankTransfer.total && this.originAccountBalance !== null) {
        this.showErrorSwal(`Total must be less than the balance of the origin account    (${this.originAccountCurrency} ${this.originAccountBalance})`, 'Incorrect amount')
        this.bankTransfer.total = 0.00000
      }
    },
  },
  async created() {
    this.isLoading = true
    this.onControl = true
    this.idCompany = this.$route.params.id
    this.bankTransfer.company_id = this.idCompany
    this.invoice.company_id = this.idCompany
    this.addPreloader()
    const data = await CompaniesService.getCompanyCountryById({
      company_id: this.idCompany,
    })
    const { country } = data.data[0]
    this.bankTransfer.currency = country === 'US' ? 2 : 1
    this.bankTransfer.payment_date = moment().format()
    await this.getBankAccounts()
    await this.getBankAccountsOrigin()
    await this.getOtherCompanies()
    this.bankTransfer.currency = country === 'US' ? 2 : 1
    if (country === 'US' && this.bankAccounts.length === 1) {
      this.checkAddButton = true
    } else if (country === 'PE' && this.bankAccounts.length === 2) {
      this.checkAddButton = true
    } else {
      this.checkAddButton = false
    }
    this.isLoading = false
    this.removePreloader()
  },
  methods: {
    calculateNewBalance(balance, incomes, expenses) {
      return Number(balance) + Number(incomes) - Number(expenses)
    },
    close() {
      this.$emit('close')
    },
    async clearData() {
      await this.getBankAccountsOrigin()
      await this.getBankAccounts()
      this.bankTransfer.transaction_id = 0
      this.bankTransfer.company_id = this.idCompany
      this.bankTransfer.payee = null
      this.bankTransfer.payment_account = null
      this.bankTransfer.payment_date = moment().format()
      this.bankTransfer.payment_method = null
      this.bankTransfer.doc_no = null
      this.bankTransfer.memo = null
      this.bankTransfer.mailling_address = null
      this.bankTransfer.status = null
      this.bankTransfer.previous_total = null
      this.bankTransfer.total = 0
      this.bankTransfer.destination_company_id = false
      this.bankTransfer.is_intercompany_transfer = false
      this.bankTransfer.exchange = 0
      this.bankTransfer.bank_account_dest_id = null
      this.files = []
      this.invoice.company_id = null
      this.invoice.payment_account = null
      this.invoice.income_date = null
      this.invoice.exchange = null
      this.invoice.doc_no = null
      this.invoice.bank_account_orig_id = null
      this.$refs['file-manager'].removeAllFiles()
      this.invoice.bank_account_dest_id = null
      this.invoice.currency = null
      this.invoice.is_intercompany_transfer = null
    },
    async getOtherCompanies() {
      try {
        const data = await CompaniesService.getOtherCompanies({
          id: this.idCompany,
        })
        this.otherCompanies = data.data
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async getTransaction() {
      try {
        const data = await CompaniesService.getTransactionExpense({
          transaction_id: this.transactionId,
        })
        if (data.status === 200) {
          this.bankTransfer = data.data[0]
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async getBankAccountsOrigin() {
      try {
        const data = await CompaniesService.getAllManBankAccounts({
          idcompany: this.idCompany,
        })
        this.bankAccounts = data.data
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async getAllBankAccounts() {
      await this.getBankAccounts()
      await this.getBankAccountsOrigin()
    },
    async getBankAccounts() {
      try {
        const data = await CompaniesService.getAllManBankAccounts({
          idcompany: this.idCompany,
        })
        this.bankAccountsDestination = data.data
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async getBankAccountsDestination() {
      try {
        const data = await CompaniesService.getAllManBankAccounts({
          idcompany: this.bankTransfer.destination_company_id,
        })
        this.bankAccountsDestination = data.data
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    deleteFile(index, file) {
      this.bankTransfer.files.splice(index, 1)
      this.deletedFiles.push(file.id)
    },
    async upload(stat) {
      this.validator = true
      if (this.originAccountBalance >= this.bankTransfer.total) {
        try {
          const result = await this.$refs.form.validate()
          if (result) {
            const response = await this.showConfirmSwal()
            if (response.isConfirmed) {
              this.invoice.company_id = this.bankTransfer.company_id
              if (this.bankTransfer.is_intercompany_transfer) this.invoice.company_id = this.bankTransfer.destination_company_id
              this.invoice.payment_account = this.bankTransfer.payment_account
              this.invoice.income_date = this.bankTransfer.payment_date
              this.invoice.exchange = this.bankTransfer.exchange
              this.invoice.doc_no = this.bankTransfer.doc_no
              this.invoice.bank_account_orig_id = this.bankTransfer.payment_account
              this.invoice.currency = this.destinationAccountCurrencyNumber
              this.invoice.bank_account_dest_id = this.bankTransfer.bank_account_dest_id
              this.invoice.is_intercompany_transfer = this.bankTransfer.is_intercompany_transfer
              const formData = new FormData()
              this.files.forEach(file => {
                formData.append('images[]', file, file.name)
              })
              this.addPreloader()
              formData.append('header', JSON.stringify(this.bankTransfer))
              formData.append('created_by', this.currentUser.user_id)
              formData.append('status', 1)
              formData.append('total', this.bankTransfer.total)
              const headers = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                // eslint-disable-next-line func-names
                onUploadProgress: function (progressEvent) {
                  this.uploadPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total,
                  )
                }.bind(this),
              }
              let data = await CompaniesService.saveTransactionExpense(formData, headers)
              if (data.status === 200) {
                const formData2 = new FormData()
                this.files.forEach(file => {
                  formData2.append('images[]', file, file.name)
                })
                const totalIncome = this.destinationAccountCurrencyNumber === 1 ? this.bankTransfer.total * this.bankTransfer.exchange : this.bankTransfer.total / this.bankTransfer.exchange
                formData2.append('header', JSON.stringify(this.invoice))
                formData2.append('created_by', this.currentUser.user_id)
                formData2.append('status', 1)
                formData2.append('total', this.bankTransfer.is_intercompany_transfer ? this.bankTransfer.total : Number(totalIncome).toFixed(2))
                data = await CompaniesService.saveInvoice(formData2, headers)
                if (data.status === 200) this.removePreloader()
                if (this.deletedFiles.length > 0) {
                  await CompaniesService.deleteTransactionFile({
                    deleted_files: this.deletedFiles,
                  })
                  await CompaniesService.deleteIncomeFile({
                    deleted_files: this.deletedFiles,
                  })
                }
                if (stat !== 1) {
                  this.$emit('reload')
                  this.close()
                  this.showSuccessSwal()
                } else {
                  this.clearData()
                }
              }
            }
          }
        } catch (e) {
          this.showErrorSwal(e)
          this.removePreloader()
        }
      } else {
        this.showWarningSwal('Important!', 'Amount to transfer can not be greater than the balance of the origin account')
      }
    },
  },
}
</script>
<style>
.tree-modal .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: calc(100vh);
  display: flex;
  position: fixed;
  z-index: 100000;
}
@media (min-width: 576px){
  .transaction-modal .modal-dialog-scrollable .modal-content{
    max-height: calc(100vh);
  }
}
</style>
<style scoped>
.input-money-style{
  width: 100%; border: 1px solid #d8d6de; border-radius: 0.357rem; padding: 0.438rem 1rem; height: 2.714rem;
}
.input-money-specific{
  padding: 2.5%;
}
.mb-half{
  margin-bottom: 0.3rem;
}
</style>
