<template>
  <b-modal
    v-model="onControl"
    style="transition: all 0.4s ease-in-out"
    title="VENDORS"
    title-tag="h3"
    modal-class="modal-primary"
    size="xl"
    hide-footer
    @hidden="close"
  >
    <div>
      <div class="mb-1 d-flex justify-content-between">
        <div />
        <b-button
          variant="success"
          @click="openCustomerModal"
        ><feather-icon
          icon="PlusIcon"
          size="15"
          class="text-white mr-1"
        />ADD </b-button>
      </div>
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refVendorsTable'].refresh()"
        @reset-all-filters="$refs['refVendorsTable'].refresh()"
      >
<!--        <template #buttons>-->
<!--          <b-form-datepicker-->
<!--            v-model="fromDate"-->
<!--            reset-button-->
<!--            style="width: 150px !important; margin-left: 7.5rem"-->
<!--            placeholder="From"-->
<!--            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"-->
<!--            locale="en"-->
<!--            @input="$refs['refVendorsTable'].refresh()"-->
<!--          />-->
<!--          <b-form-datepicker-->
<!--            v-model="toDate"-->
<!--            reset-button-->
<!--            class="ml-1"-->
<!--            style="width: 150px !important;"-->
<!--            placeholder="To"-->
<!--            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"-->
<!--            locale="en"-->
<!--            @input="$refs['refVendorsTable'].refresh()"-->
<!--          />-->
<!--        </template>-->
        <template #table>
          <b-table
            ref="refVendorsTable"
            no-border-collapse
            class="position-relative"
            :fields="fields"
            show-empty
            no-provider-filtering
            sticky-header="50vh"
            primary-key="id"
            responsive="sm"
            :items="vendorsProvider"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :filter="searchInput"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(vendor)="data">
              {{ data.item.print_on_check_as? data.item.display_name_as : data.item.company }}
            </template>
            <template #cell(display_name_as)="data">
              <span :class="textLink">{{ data.item.display_name_as }}</span>
            </template>
            <template #cell(full_name)="data">
              {{ data.item.owner_first_name }} {{ data.item.owner_middle_name }} {{ data.item.owner_last_name }}
            </template>
            <template #cell(created_by)="data">
              {{ data.item.created_by }} <br> {{ data.item.created_at | myGlobalWithHour }}
            </template>

            <template #cell(updated_by)="data">
              {{ data.item.updated_by }} <br> {{ data.item.updated_at | myGlobalWithHour }}
            </template>
            <template #cell(actions)="data">
              <feather-icon
                class="mr-1 cursor-pointer text-warning"
                size="20"
                icon="EditIcon"
                @click="editVendor(data.item.id, data.item)"
              />
              <feather-icon
                v-if="!data.item.is_inactive"
                class="mr-1 pointer text-info"
                size="20"
                icon="XCircleIcon"
                @click="deactivateVendor(data.item.id)"
              />
              <feather-icon
                v-else
                class="mr-1 pointer text-success"
                size="20"
                icon="CheckCircleIcon"
                @click="activateVendor(data.item.id)"
              />
              <feather-icon
                class="cursor-pointer text-danger"
                size="20"
                icon="TrashIcon"
                @click="deleteVendor(data.item.id)"
              />
            </template>
          </b-table>
          <create-edit-vendor-modal
            v-if="createCustomerModalController"
            :vendorProp = "selectedVendor"
            @close="closeCreateCustomerModal"
            @reload="$refs['refVendorsTable'].refresh()"
          />
        </template>
      </filter-slot>
    </div>

  </b-modal>
</template>

<script>
import CompaniesService from '@/views/management/views/companies/companies.service'
import CustomerModal from '@/views/management/views/companies/dashboard/incomes-module/modals/customers-modal/CustomerModal'
import CreateCustomer from '@/views/management/views/companies/dashboard/incomes-module/modals/customers-modal/modals/CreateCustomer'
import CreateEditVendorModal from '@/views/management/views/companies/dashboard/expenses-module/modals/CreateEditVendorModal'
import FiltersVendors from "@/views/management/views/companies/dashboard/expenses-module/modals/filters.vendors";
import { mapGetters } from 'vuex'

export default {
  components: {
    CustomerModal,
    CreateCustomer,
    CreateEditVendorModal,
  },
  data() {
    return {
      createCustomerModalController: false,
      filters: FiltersVendors,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Name of vendor ...',
        model: '',
      },
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      fields: [
        {
          key: 'vendor',
          label: 'Vendor',
        },
        {
          key: 'display_name_as',
          label: 'Display name as',
        },
        {
          key: 'mobile',
          label: 'Mobile',
        },
        {
          key: 'phone',
          label: 'Phone',
        },
        {
          key: 'full_name',
          label: 'Owner',
        },
        {
          key: 'business_id',
          label: 'Business Id',
        },
        {
          key: 'created_by',
          label: 'Created By',
        },
        {
          key: 'updated_by',
          label: 'Updated By',
        },
        {
          key: 'actions',
          label: 'Actions',
          thStyle: {
            width: '150px',
          },
        },
      ],
      searchInput: '',
      selectedVendorItem: [],
      isBusy: false,
      items: [],
      idCompany: 0,
      onControl: false,
      fromDate: '',
      toDate: '',
      selectedVendor: null,
    }
  },
  async created() {
    this.onControl = true
    this.idCompany = this.$route.params.id
  },
  methods: {
    closeCreateCustomerModal() {
      this.createCustomerModalController = false
      this.selectedVendor = null
    },
    openCustomerModal() {
      this.createCustomerModalController = true
    },
    async vendorsProvider(ctx) {
      try {
        const data = await CompaniesService.getVendors({
          orderby: 1,
          perpage: ctx.perPage,
          page: ctx.currentPage,
          order: 'desc',
          campo: this.filterPrincipal.model,
          idcompany: this.idCompany,
          from: this.filters[0].model,
          to: this.filters[1].model,
        })
        if (data.status === 200) {
          this.items = data.data.data
          this.startPage = data.data.from
          this.paginate.perPage = data.data.per_page
          this.paginate.currentPage = data.data.current_page
          this.totalRows = data.data.total
          this.toPage = data.data.to
          return this.items || []
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async deleteVendor(id) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const data = await CompaniesService.deleteVendor({
            deleted_by: this.currentUser.user_id,
            vendor_id: id,
          })
          if (data.status === 200) {
            this.showSuccessSwal('Success!', 'Vendor deleted successfully')
            this.$refs.refVendorsTable.refresh()
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async activateVendor(id) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const data = await CompaniesService.activateVendor({
            vendor_id: id,
          })
          if (data.status === 200) {
            this.showSuccessSwal('Success!', 'Vendor activated successfully')
            this.$refs.refVendorsTable.refresh()
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async deactivateVendor(id) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const data = await CompaniesService.deactivateVendor({
            vendor_id: id,
          })
          if (data.status === 200) {
            this.showSuccessSwal('Success!', 'Vendor deactivated successfully')
            this.$refs.refVendorsTable.refresh()
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    editVendor(id, item) {
      this.selectedVendor = id
      this.selectedVendorItem = item
      this.createCustomerModalController = true
    },
    close() {
      this.$emit('close')
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
    }),
  },
}
</script>

<style scoped>

</style>
