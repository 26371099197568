<template>
  <b-modal
    id="modal-closing"
    ref="modal"
    v-model="onControl"
    :header-bg-variant=" isDarkSkin?'dark' : 'light'"
    modal-class="tree-modal modal-primary transaction-modal"
    body-class="p-0"
    title-tag="h3"
    title-class="h3 text-dark font-weight-bolder"
    footer-bg-variant="dark"
    scrollable
    hide-header
    @hidden="close"
  >
    <b-row
      style="width: 100vw !important"
      class="m-0 px-1 pt-2"
      :class="isDarkSkin? 'bg-dark' : 'bg-light'"
    >
      <div
        style="width: 100% !important"
        class="d-flex justify-content-between"
      >
        <h2 class="d-inline text-dark font-weight-bolder mb-2 ml-1">
          EXPENSE {{ expense.doc_no ? "no." + expense.doc_no : "" }}
        </h2>
        <div
          style="background: white !important;border: none; width: 30px !important; height: 30px !important;"
          class=" bg-white py-0 cursor-pointer rounded close-but"
          @click="close()"
        >
          <feather-icon
            class="text-primary"
            style="margin: 5px !important"
            icon="XIcon"
            size="18"
          />
        </div>
      </div>
      <b-row
        class="m-0 mt-1 mb-1"
        style="width: 100% !important"
      >
        <b-col md="9">
          <b-row>
            <b-col md="3">
              <span class="d-flex justify-content-between mb-half">
                <span>Payee</span>
                <feather-icon
                  icon="PlusCircleIcon"
                  size="22"
                  class="text-primary cursor-pointer"
                  @click="openVendorModal"
                />
              </span>
              <v-select
                v-model="expense.payee"
                class="bg-white"
                placeholder="Select option"
                :options="vendors"
                label="company"
                :disabled="isConfirmed"
              >
                <template #selected-option="{ print_on_check_as, display_name_as, company}">
                  <span>{{ print_on_check_as ? display_name_as : company }}</span>
                </template>
                <template #option="{ print_on_check_as, display_name_as, company}">
                  <span>{{ print_on_check_as ? display_name_as : company }}</span>
                </template>
              </v-select>
            </b-col>
            <b-col md="3">
              <span class="d-flex justify-content-between mb-half">
                <span>Payment account<span
                  v-b-tooltip.hover.top="'Select the payment account you used for this expense.'"
                  class="cursor-pointer"
                >&nbsp;(?)</span></span>
                <feather-icon
                  v-if="!checkAddButton && !isLoading"
                  icon="PlusCircleIcon"
                  size="22"
                  class="text-primary cursor-pointer"
                  @click="openBankAccountModal"
                />
              </span>
              <b-skeleton
                v-if="isLoading"
                type="input"
              />
              <v-select
                v-else
                v-model="expense.payment_account"
                class="bg-white"
                :reduce="bank_name => bank_name.id"
                :options="bankAccounts"
                label="bank_name"
                :disabled="isConfirmed"
              >
                <template
                  #selected-option-container="{ option}"
                >
                  <div class="vs__selected">
                    <span v-if="bankExists">
                      {{ option.bank_name }}
                    </span>
                    <span v-else>This bank account was deleted: {{ option.bank_name }}</span>
                  </div>
                </template>
              </v-select>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Currency"
              >
                <b-form-select
                  v-model="expense.currency"
                  :class="isDarkSkin? '': 'bg-white'"
                  :options="currencies"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              class="d-flex align-items-center"
            >
              <span class="mt-1 font-weight-bolder">Balance  &nbsp; &nbsp; </span>
              <money
                :value="balance"
                class="p-0 border-0 ml-1 font-weight-bolder text-secondary"
                style="font-size: 25px; background: transparent"
                v-bind="money"
                disabled
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <span class="d-flex justify-content-between mb-half">
                <span>Payment date</span>
              </span>
              <b-form-datepicker
                v-model="expense.payment_date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="en"
                :disabled="isConfirmed"
              />
            </b-col>
            <b-col md="3">
              <span class="d-flex justify-content-between mb-half">
                <span>Payment method</span>
                <feather-icon
                  icon="PlusCircleIcon"
                  size="22"
                  class="text-primary cursor-pointer"
                  @click="openPaymentAccountModal"
                />
              </span>
              <v-select
                v-model="expense.payment_method"
                :reduce="name => name.id"
                class="bg-white"
                :options="paymentMethods"
                label="name"
                :disabled="isConfirmed"
              />
            </b-col>
            <b-col md="2">
              <span class="d-flex justify-content-between mb-half">
                <span>Ref no.</span>
              </span>
              <b-form-input
                v-model="expense.doc_no"
                :disabled="isConfirmed"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          md="3"
          class="mt-1"
        >
          <div class="text-right mb-1 font-weight-bolder">
            Amount
          </div>
          <money
            v-if="money"
            :value="totalAmount"
            class="input-money-style input-money-specific text-right text-secondary font-weight-bolder"
            style="height: 40px;font-size: 30pt; width: 100%;"
            disabled
            v-bind="money"
          />
        </b-col>
      </b-row>
    </b-row>
    <transaction-table-component
      :expense="expense"
      :parent-accounts="parentAccounts"
      :is-confirmed="isConfirmed"
      :money="money"
      :deleteds="deleteds"
      :is-expense="true"
      :total-amount="totalAmount"
      :is-loading="isLoading"
      @openModal="categoryModalController=true"
    />
    <b-row
      class="mt-2 ml-1 d-flex justify-content-between"
      style="width: 100%;height: 80px"
    >
      <b-col cols="5">
        <b-form-group
          label="Memo"
        >
          <b-form-textarea
            v-model="expense.memo"
            style="width: 100%; height: 275px"
            :disabled="isConfirmed"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="!isConfirmed"
        cols="7"
      >
        <b-col md="12">
          <b-row>
            <b-col md="6">
              <div class="mb-half">
                Attachments
              </div>
            </b-col>
            <b-col md="6">
              <div class="mb-half">
                Maximum size: 20MB
              </div>
            </b-col>
          </b-row>
          <drag-and-drop
            ref="file-manager"
            v-model="files"
            source="expense"
            :files-array="files"
            :files-to-show="expense.files"
            @delete-file="deleteFile"
          />
        </b-col>
      </b-col>
    </b-row>
    <template #modal-footer="{cancel}">
      <div
        style="width: 100%;"
        class="d-flex justify-content-between"
      >
        <div>
          <b-button
            class="mr-1"
            @click="cancel()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="expense.status == null"
            @click="clearData"
          >
            Clear
          </b-button>
        </div>
        <b-dropdown
          dropup
          split
          variant="primary"
          text="Save and Close"
          @click="upload"
        >
          <b-dropdown-item @click="upload(1)">
            Save and new
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
    <create-edit-vendor-modal
      v-if="vendorModalController"
      @close="closeVendorModal"
      @reload="getVendors"
    />
    <create-category-com-modal
      v-if="categoryModalController"
      :company-id="idCompany"
      @close="closeCategoryModal"
      @reload="getParentAccounts"
    />
    <create-bank-account
      v-if="bankAccModalController"
      :edit="false"
      @close="closeBankAccountModal"
      @refresh="getBankAccounts"
    />
    <create-payment-modal
      v-if="paymentModalController"
      @close="closePaymentAccountModal"
      @reload="getPaymentMethods"
    />
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import dragAndDrop from '@/views/commons/utilities/DragAndDrop'
import CompaniesService from '@/views/management/views/companies-old/companies.service'
import CategoriesService from '@/views/management/views/categories/categories.service'
import CreateEditVendorModal from '@/views/management/views/companies-old/dashboard/expenses-module/modals/CreateEditVendorModal'
import CreateCategoryComModal from '@/views/management/views/companies-old/dashboard/categories-module/modals/CreateCategoryComModal'
import CreateBankAccount from '@/views/management/views/companies-old/dashboard/bank-accounts-module/modals/CreateBankAccount'
import CreatePaymentModal from '@/views/management/views/companies-old/dashboard/expenses-module/modals/CreatePaymentModal'
import moment from 'moment'
import { mapGetters } from 'vuex'
import TransactionTableComponent
from '@/views/management/views/companies-old/dashboard/expenses-module/components/TransactionTableComponent'

export default {
  components: {
    vSelect,
    dragAndDrop,
    CreateEditVendorModal,
    CreateCategoryComModal,
    CreateBankAccount,
    CreatePaymentModal,
    TransactionTableComponent,
  },
  props: {
    transactionId: null,
  },
  data() {
    return {
      vendorModalController: false,
      isLoading: false,
      categoryModalController: false,
      bankAccModalController: false,
      paymentModalController: false,
      idDrag: 1,
      checkAddButton: false,
      files: [],
      onControl: false,
      lines: [{ index: 1 }, { index: 2 }],
      amount: 0,
      deleteds: [],
      idCompany: '',
      expense: {
        transaction_id: null,
        company_id: this.idCompany,
        payee: null,
        payment_account: null,
        bank_account_dest_id: null,
        payment_date: null,
        due_date: null,
        payment_method: null,
        doc_no: null,
        term_id: null,
        memo: null,
        mailling_address: null,
        exchange: null,
        type: 1,
        status: null,
        currency: null,
        previous_total: null,
        is_intercompany_transfer: false,
        detail: [
          {
            category: null,
            description: null,
            amount: 0,
            id: null,
            identifier: null,
          },
          {
            category: null,
            description: null,
            amount: 0,
            id: null,
            identifier: null,
          },
        ],
        files: null,
      },
      bankAccounts: [],
      vendors: [],
      parentAccounts: [],
      paymentMethods: [],
      companyCountry: '',
      currencies: [
        {
          value: 1,
          text: 'S/',
        },
        {
          value: 2,
          text: '$',
        },
      ],
      images: [],
      deletedFiles: [],
      notFoundCategories: [],
      bankExists: true,
    }
  },
  computed: {
    // eslint-disable-next-line consistent-return
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    balance() {
      for (let i = 0; i < this.bankAccounts.length; i++) {
        if (
          this.bankAccounts[i].id == this.expense.payment_account
        ) {
          return this.calculateNewBalance(
            this.bankAccounts[i].balance,
            this.bankAccounts[i].total_incomes,
            this.bankAccounts[i].total_expenses,
          )
        }
      }
    },
    totalAmount() {
      let sum = 0
      for (let i = 0; i < this.expense.detail.length; i++) {
        sum += Number(this.expense.detail[i].amount)
      }
      return sum.toFixed(2)
    },
    money() {
      for (let i = 0; i < this.currencies.length; i++) {
        if (this.currencies[i].value == this.expense.currency) {
          return {
            decimal: '.',
            thousands: ',',
            prefix: this.currencies[i].text,
            precision: 2,
            masked: false,
          }
        }
      }
    },
    isConfirmed() {
      return this.expense.status == 2
    },
    isAtLeastOneItemAdded() {
      for (let i = 0; i < this.expense.detail.length; i++) {
        if (this.expense.detail[i].category != null || this.expense.detail[i].description || this.expense.detail[i].amount != 0) {
          return true
        }
      }
      return false
    },
  },
  watch: {
    'expense.payment_account': function (newVal, oldVal) {
      for (let i = 0; i < this.bankAccounts.length; i++) {
        if (this.bankAccounts[i].id == newVal) {
          this.expense.currency = this.bankAccounts[i].currency
        }
      }
    },
  },
  async created() {
    this.isLoading = true
    this.onControl = true
    this.idCompany = this.$route.params.id
    this.expense.company_id = this.idCompany
    this.addPreloader()
    const data = await CompaniesService.getCompanyCountryById({
      company_id: this.idCompany,
    })
    const { country } = data.data[0]
    this.expense.payment_date = moment().format()
    this.expense.currency = country === 'US' ? 2 : 1
    this.companyCountry = country
    await this.getBankAccounts()
    await this.getVendors()
    await this.getParentAccounts()
    await this.getPaymentMethods()
    if (this.companyCountry === 'US' && this.bankAccounts.length === 1) {
      this.checkAddButton = true
    } else if (this.companyCountry === 'PE' && this.bankAccounts.length === 2) {
      this.checkAddButton = true
    } else {
      this.checkAddButton = false
    }
    if (this.transactionId != null) {
      this.bankExists = false
      this.bankAccounts.forEach(bank => {
        if (bank.id === this.expense.payment_account) this.bankExists = true
      })
      let bankacc = null
      if (!this.bankExists) {
        bankacc = await CompaniesService.getBankAccountById({
          idbankaccount: this.expense.payment_account,
        })
        this.expense.payment_account = bankacc.data
      }
    }
    this.isLoading = false
    this.removePreloader()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    closeVendorModal() {
      this.vendorModalController = false
    },
    openVendorModal() {
      this.vendorModalController = true
    },
    closeCategoryModal() {
      this.categoryModalController = false
    },
    openCategoryModal() {
      this.categoryModalController = true
    },
    closeBankAccountModal() {
      this.bankAccModalController = false
    },
    openBankAccountModal() {
      this.bankAccModalController = true
    },
    closePaymentAccountModal() {
      this.paymentModalController = false
    },
    openPaymentAccountModal() {
      this.paymentModalController = true
    },
    addLines() {
      for (let i = 0; i < 4; i++) {
        const obj = {
          category: null,
          description: null,
          amount: 0,
          id: null,
          identifier:
              new Date().getUTCMilliseconds() + Math.random() * 10000000000,
        }
        this.expense.detail.push(obj)
      }
    },
    clearAllLines() {
      for (let i = 2; i < this.expense.detail.length; i++) {
        this.deleteds.push(this.expense.detail[i].id)
      }
      const index = 1
      this.expense.detail.splice(
        index + 1,
        this.expense.detail.length - (index + 1),
      )
    },
    async getTransaction() {
      try {
        const data = await CompaniesService.getTransactionExpense({
          transaction_id: this.transactionId,
        })
        if (data.status === 200) {
          this.expense = data.data[0]
        }
        for (let i = 0; i < this.vendors.length; i++) {
          if (this.vendors[i].id == this.expense.payee) {
            this.expense.payee = this.vendors[i]
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    clearData() {
      this.expense.transaction_id = null
      this.expense.company_id = this.idCompany
      this.expense.payee = null
      this.expense.payment_account = null
      this.expense.payment_date = moment().format()
      this.expense.payment_method = null
      this.expense.doc_no = null
      this.expense.memo = null
      this.expense.mailling_address = null
      this.expense.type = 1
      this.expense.status = null
      this.expense.previous_total = null
      this.$set(this.expense, 'detail', [
        {
          category: null,
          description: null,
          amount: 0,
          id: null,
          identifier: null,
        },
        {
          category: null,
          description: null,
          amount: 0,
          id: null,
          identifier: null,
        },
      ])
      this.expense.files = null
      this.files = []
      this.$refs['file-manager'].removeAllFiles()
      this.images = []
    },
    async getBankAccounts() {
      try {
        const data = await CompaniesService.getAllManBankAccounts({
          idcompany: this.idCompany,
        })
        this.bankAccounts = data.data
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async getVendors() {
      try {
        const data = await CompaniesService.getAllVendors({
          idcompany: this.idCompany,
        })
        this.vendors = data.data
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async getParentAccounts() {
      const data = await CategoriesService.getParentAccounts({
        company_id: this.idCompany,
      })
      this.parentAccounts = data

      for (let i = 0; i < this.parentAccounts.length - 1; i++) {
        if ((this.parentAccounts[i].type < this.parentAccounts[i + 1].type)) {
          this.parentAccounts[i].isChild = 1
        }
      }
      if (this.transactionId != null) {
        await this.getTransaction()
      }
    },
    async getPaymentMethods() {
      const data = await CompaniesService.getPaymentMethods({
        company_id: this.idCompany,
      })
      this.paymentMethods = data.data
    },
    calculateNewBalance(balance, incomes, expenses) {
      return Number(balance) + Number(incomes) - Number(expenses)
    },
    deleteExpenseDetail(index, detail) {
      this.expense.detail.splice(index, 1)
      if (detail.id) {
        this.deleteds.push(detail.id)
      }
    },
    deleteFile(index, file) {
      this.expense.files.splice(index, 1)
      this.deletedFiles.push(file.id)
    },
    async upload(stat) {
      if (this.balance >= this.totalAmount) {
        if (this.isAtLeastOneItemAdded) {
          try {
            const response = await this.showConfirmSwal()
            if (response.isConfirmed) {
              const formData = new FormData()
              this.files.forEach(file => {
                formData.append('images[]', file, file.name)
              })
              this.addPreloader()

              formData.append('header', JSON.stringify(this.expense))
              formData.append('created_by', this.currentUser.user_id)
              formData.append('status', 1)
              formData.append('total', this.totalAmount)
              const headers = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                // eslint-disable-next-line func-names
                onUploadProgress: function (progressEvent) {
                  this.uploadPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total,
                  )
                }.bind(this),
              }
              const data = await CompaniesService.saveTransactionExpense(formData, headers)
              if (data.status === 200) {
                if (stat !== 1) {
                  this.removePreloader()
                  this.$emit('reload')
                  this.close()
                  this.showSuccessSwal()
                } else {
                  this.removePreloader()
                  this.clearData()
                }
                if (this.deleteds.length > 0) {
                  await CompaniesService.deleteTransactionDetail({
                    deleteds: this.deleteds,
                  })
                }
                if (this.deletedFiles.length > 0) {
                  await CompaniesService.deleteTransactionFile({
                    deleted_files: this.deletedFiles,
                  })
                }
              }
            }
          } catch (e) {
            this.showErrorSwal(e)
            this.removePreloader()
          }
        } else {
          this.showWarningSwal('Important!', 'Please enter at least one line item.')
        }
      } else {
        this.showWarningSwal('Important!', 'Total amount for this expense can not be greater than the balance of the payment account')
      }
    },
  },
}
</script>

<style>
.tree-modal .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: calc(100vh);
  display: flex;
  position: fixed;
  z-index: 100000;
}
@media (min-width: 576px){
  .transaction-modal .modal-dialog-scrollable .modal-content{
    max-height: calc(100vh);
  }
}
</style>

<style scoped>
.mb-half{
  margin-bottom: 0.3rem;
}
.bg-white{
  background: white !important;
}
.close-but:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}
.input-money-style{
  width: 100%; height: 2.714rem; border: transparent;
}
</style>
