<template>
  <span>
    <b-table-simple
      ref="expensesCategories"
      class="mx-1 mt-1"
      responsive="sm"
    >
      <b-thead>
        <b-tr>
          <b-th>#</b-th>
          <b-th>
            <div class="d-flex justify-content-between">
              <span>CATEGORY <span
                v-if="isExpense"
                v-b-tooltip.hover.top="'Choose a category to see where you\'re spending your money.'"
                class="cursor-pointer"
              >(?)</span></span>
              <feather-icon
                icon="PlusCircleIcon"
                size="22"
                class="text-primary cursor-pointer"
                @click="openCategoryModal"
              />
            </div>
          </b-th>
          <b-th>DESCRIPTION</b-th>
          <b-th>AMOUNT</b-th>
          <b-th />
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(line, index) in expense.detail"
          :key="index"
        >
          <b-td>{{ index +1 }}</b-td>
          <b-td style="width: 40% !important">
            <b-skeleton style="width: 40% !important" v-if="isLoading" type="input"></b-skeleton>
            <v-select
              v-else
              v-model="line.category"
              placeholder="What tax category fits?"
              :options="parentAccounts"
              label="name"
              :reduce="name => name.id"
              :disabled="isConfirmed"
            >
              <template
                #selected-option-container="{ option}"
              >
                <div class="vs__selected">
                  <span v-if="Object.keys(option).length>1">
                    <span v-if="option.type===1">{{ option.name }}</span>
                    <span v-else>{{ option.parent_name + ' : ' + option.name }}</span>
                  </span>
                  <span v-else>This category was deleted</span>
                </div>
              </template>
              <template #option="{ name, id, parent_account, type, isChild, account_type_text }">
                <span class="d-flex justify-content-between">
                  <span :style="`margin-left: ${id == parent_account?0:type*15}px`">
                    <feather-icon
                      v-if="isChild===1 || id == parent_account"
                      icon="ChevronDownIcon"
                      size="10"
                    />
                    <span v-else>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                    {{ name }}</span>
                  <span v-if="id == parent_account">{{ account_type_text }}</span>
                </span>
              </template>
            </v-select
              v-else>
          </b-td>
          <b-td>
            <b-skeleton style="width: 40% !important" v-if="isLoading" type="input"></b-skeleton>
            <b-form-input
              v-else
              v-model="line.description"
              placeholder="What did you pay for?"
              :disabled="isConfirmed"
            />
          </b-td>
          <b-td>
            <b-skeleton style="width: 40% !important" v-if="isLoading" type="input"></b-skeleton>
            <template v-if="money && !isLoading">
              <money
                v-model="line.amount"
                class="form-control"
                v-bind="money"
                :disabled="isConfirmed"
              />
            </template>
          </b-td>
          <b-td class="text-right">
            <feather-icon
              v-if="!isConfirmed"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="23"
              @click="index > 1 ? deleteExpenseDetail(index, line) : null"
            />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div
      class="d-flex justify-content-between mr-2"
      style="width: 100%"
    >
      <div>
        <b-button
          variant="light"
          class="mx-1"
          :disabled="isConfirmed"
          @click="addLines"
        >
          Add Lines
        </b-button>
        <b-button
          variant="light"
          :disabled="isConfirmed"
          @click="clearAllLines"
        >
          Clear all lines
        </b-button>
      </div>
      <div v-if="money">
        <span class="font-medium-4 text-right">Total</span>
        <money
          :value="totalAmount"
          class="p-0 border-0 ml-1 mr-1 font-weight-bolder text-secondary text-right"
          style="font-size: 18px; background: transparent"
          v-bind="money"
          disabled
        />
      </div>
    </div>

  </span>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    expense: null,
    parentAccounts: null,
    isConfirmed: null,
    money: null,
    deleteds: null,
    isExpense: null,
    totalAmount: null,
    isLoading: null,
  },
  methods: {
    openCategoryModal() {
      this.$emit('openModal')
    },
    deleteExpenseDetail(index, detail) {
      this.expense.detail.splice(index, 1)
      if (detail.id) {
        this.deleteds.push(detail.id)
      }
    },
    addLines() {
      for (let i = 0; i < 4; i++) {
        const obj = {
          category: null,
          description: null,
          amount: 0,
          id: null,
          identifier:
              new Date().getUTCMilliseconds() + Math.random() * 10000000000,
        }
        this.expense.detail.push(obj)
      }
    },
    clearAllLines() {
      for (let i = 2; i < this.expense.detail.length; i++) {
        this.deleteds.push(this.expense.detail[i].id)
      }
      const index = 1
      this.expense.detail.splice(
        index + 1,
        this.expense.detail.length - (index + 1),
      )
    },
  },
}
</script>

<style scoped>

</style>
