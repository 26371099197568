<template>
  <b-col md="6">
    <validation-observer ref="form">
      <b-row>
        <b-col md="12">
          <b-form-group
            id="billing_address-1"
            :label="addressType"
            label-for="billing_address-1"
          >
            <b-skeleton
              v-if="!finishedLoading"
              type="input"
            />
            <b-form-input
              v-if="finishedLoading && !isCompanyFromUsa"
              v-model="vendor.address"
              placeholder="Please type your address"
            />
            <vue-google-autocomplete
              v-if="finishedLoading && isCompanyFromUsa"
              id="create_customer_address1"
              ref="create_customer_address1"
              v-model="vendor.address"
              classname="input-form form-control fond-white"
              placeholder="Please type your address"
              :country="['us']"
              style="height: 35px !important;"
              @placechanged="getAddressData"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col md="6">
              <b-form-group
                id="billing_country-1"
                label="City/Town"
                label-for="billing_country-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="billing_country-1"
                  v-model="vendor.city"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="billing_state-1"
                label="State/Province"
                label-for="billing_state-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="billing_state-1"
                  v-model="vendor.state"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
            id="billing_zip_code-1"
            label="ZIP code"
            label-for="billing_zip_code-1"
          >
            <b-skeleton
              v-if="!finishedLoading"
              type="input"
            />
            <b-form-input
              v-else
              id="billing_zip_code-1"
              v-model="vendor.zip_code"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
            id="note-1"
            label="Notes"
            label-for="note-1"
          >
            <b-skeleton-wrapper :loading="!finishedLoading">
              <template #loading>
                <b-skeleton
                  height="260px"
                  type="input"
                />
              </template>
              <b-form-textarea
                id="note-1"
                v-model="vendor.note"
                class="mt-1"
                style="height: 252px !important"
                trim
              />
            </b-skeleton-wrapper>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-col>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    mood: null,
    vendor: { type: Object },
    finishedLoading: null,
    isCompanyFromUsa: null,
  },
  data() {
    return {
      addressType: '',
    }
  },
  created() {
    if (this.mood === 1) {
      this.addressType = 'Billing Address'
    } else {
      this.addressType = 'Address'
    }
  },
  methods: {
    getAddressData(addressPrincipal) {
      const mainAddress = addressPrincipal.street_number
        ? `${addressPrincipal.street_number} ${addressPrincipal.route}`
        : addressPrincipal.route
      this.vendor.address = mainAddress
      this.vendor.city = addressPrincipal.locality
      this.vendor.state = addressPrincipal.administrative_area_level_1
      this.vendor.zip_code = addressPrincipal.postal_code
    },
  },
}
</script>

<style scoped>

</style>
