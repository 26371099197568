<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['expensesTable'].refresh()"
      @reset-all-filters="resetAllFilters"
    >
      <template #custom-vselect>
        <v-select
          v-model="categoryId"
          label="name"
          :reduce="name => name.id"
          :options="parentAccounts"
        >
          <template
            #selected-option="{ name, parent_name, type }"
          >
            <span v-if="type===1">{{ name }}</span>
            <span v-else>{{ parent_name + ' : ' + name }}</span>
          </template>
          <template #option="{ name, id, parent_account, type, isChild, account_type_text}">
            <span class="d-flex justify-content-between">
              <span :style="`margin-left: ${id == parent_account?0:type*15}px`">
                <feather-icon
                  v-if="isChild===1 || id == parent_account"
                  icon="ChevronDownIcon"
                  size="10"
                />
                <span v-else>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                {{ name }}</span>
              <span v-if="id == parent_account">{{ account_type_text }}</span>
            </span>
          </template>
        </v-select>
      </template>
      <template #table>
        <b-table
          ref="expensesTable"
          no-border-collapse
          class="position-relative font-small-3"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          small
          responsive="sm"
          :items="expensesProvider"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(payment_date)="data">
            {{ data.item.payment_date | myGlobal }}
          </template>
          <template #cell(category)="data">
            <li
              v-for="category in data.item.json_data"
              :key="category.id"
            >
              {{ category.child_account == category.parent_account ? "" : category.parent_account_text + ":" }}{{ category.name }}
            </li>
          </template>
          <template #cell(total)="data">
            <span>
              {{
                data.item.currency
              }} {{
                formatPrice(data.item.total)
              }}</span>
          </template>
          <template #cell(created_by)="data">
            {{ data.item.first_name }} {{ data.item.last_name }} <br>
            {{ data.item.created_at | myGlobalDay }}
          </template>
          <template #cell(actions)="data">
            <feather-icon
              size="18"
              class="mr-1 cursor-pointer  text-success"
              icon="DownloadCloudIcon"
              @click="exportPdfCheck(data.item)"
              v-if="data.item.type_number == 2"
            />
            <feather-icon
              size="18"
              class="mr-1 cursor-pointer  text-warning"
              icon="EditIcon"
              @click="openTransactionModal(data.item.type_number, data.item)"
            />
            <feather-icon
              size="18"
              class="cursor-pointer  text-danger"
              icon="TrashIcon"
              @click="deleteExpense(data.item)"
            />
          </template>
        </b-table>
        <transition name="slide-fade">
          <create-bill-transaction-modal
            v-if="openBillTranModalController"
            @close="closeBillTranModal"
          />
        </transition>
        <list-vendors-modal
          v-if="openListVendorsModalController"
          @close="closeListVendorsTranModal"
        />
        <create-expense-transaction-modal
          v-if="expenseModalCont"
          :transaction-id="idToSend"
          @close="expenseModalCont = false"
          @reload="$refs['expensesTable'].refresh()"
        />
        <create-check-transaction-modal
          v-if="checkModalCont"
          :transaction-id="idToSend"
          @close="checkModalCont = false"
          @reload="$refs['expensesTable'].refresh()"
        />
        <create-bill-transaction-modal
          v-if="billModalCont"
          :transaction-id="idToSend"
          @close="billModalCont = false"
          @reload="$refs['expensesTable'].refresh()"
        />
      </template>
    </filter-slot>
  </div>
</template>

<script>
import CompaniesService from '@/views/management/views/companies/companies.service'
import expensesTableFilters from '@/views/management/views/companies/dashboard/expenses-module/expenses-table.filters'
import CategoriesService from '@/views/management/views/categories/categories.service'
import CreateBillTransactionModal from '@/views/management/views/companies/dashboard/expenses-module/modals/CreateBillTransactionModal'
import ListVendorsModal from '@/views/management/views/companies/dashboard/expenses-module/modals/ListVendorsModal'
import { mapGetters } from 'vuex'
import CreateExpenseTransactionModal from '@/views/management/views/companies/dashboard/expenses-module/modals/CreateExpenseTransactionModal'
import CreateCheckTransactionModal from '@/views/management/views/companies/dashboard/expenses-module/modals/CreateCheckTransactionModal'
import vSelect from 'vue-select'

export default {
  components: {
    CreateBillTransactionModal,
    ListVendorsModal,
    CreateExpenseTransactionModal,
    CreateCheckTransactionModal,
    vSelect,
  },
  data() {
    return {
      expenseModalCont: false,
      checkModalCont: false,
      billModalCont: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Name of payee...',
        model: '',
      },
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      itemToSend: null,
      searchInput: '',
      show: false,
      fields: [
        {
          key: 'payment_date',
          label: 'DATE',
        },
        {
          key: 'type',
          label: 'TYPE',
        },
        {
          key: 'doc_no',
          label: 'NO.',
        },
        {
          key: 'display_name_as',
          label: 'PAYEE',
        },
        {
          key: 'category',
          label: 'CATEGORY',
          thStyle: {
            width: '350px',
          },
        },
        {
          key: 'memo',
          label: 'MEMO',
        },
        {
          key: 'total',
          label: 'TOTAL',
          thStyle: {
            width: '100px',
          },
        },
        {
          key: 'created_by',
          label: 'CREATED BY',
          thStyle: {
            width: '180px',
          },
        },
        {
          key: 'actions',
          label: 'ACTIONS',
          thStyle: {
            width: '100px',
          },
        },
      ],
      isBusy: false,
      transactions: [],
      idToSend: '',
      idCompany: '',
      currencyOptions: [{ id: 1, label: 'S/.' }, { id: 2, label: '$' }],
      typeOptions: [{ id: 1, label: 'EXPENSE' }, { id: 2, label: 'CHECK' }, { id: 3, label: 'BILL' }],
      categoryOptions: [],
      openBillTranModalController: false,
      openListVendorsModalController: false,
      filters: expensesTableFilters,
      categoryId: '',
      parentAccounts: [],
    }
  },
  async created() {
    this.idCompany = this.$route.params.id
    await this.getParentAccounts()
    this.filters[2].options = this.currencyOptions
    this.filters[3].options = this.categoryOptions
    this.filters[4].options = this.typeOptions
  },
  methods: {
    async expensesProvider(ctx) {
      try {
        const data = await CompaniesService.getManTransactions({
          orderby: 1,
          order: 'desc',
          campo: this.filterPrincipal.model,
          company_id: Number(this.idCompany),
          per_page: ctx.perPage,
          page: ctx.currentPage,
          from: this.filters[0].model,
          to: this.filters[1].model,
          filtercategory: this.filters[4].model,
          categ: this.categoryId,
          currencyid: this.filters[2].model,
        })
        if (data.status === 200) {
          this.transactions = data.data.data
          this.startPage = data.data.from
          this.paginate.perPage = data.data.per_page
          this.paginate.currentPage = data.data.current_page
          this.endPage = data.data.last_page
          this.totalRows = data.data.total
          this.toPage = data.data.to
          return this.transactions || []
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    resetAllFilters() {
      this.filters.forEach(filter => {
        filter.model = null
      })
      this.filterPrincipal.model = null
      this.categoryId = ''
      this.$refs.expensesTable.refresh()
    },
    async getParentAccounts() {
      const data = await CategoriesService.getParentAccounts({
        company_id: this.$route.params.id,
      })
      this.parentAccounts = data

      for (let i = 0; i < this.parentAccounts.length - 1; i++) {
        if ((this.parentAccounts[i].type < this.parentAccounts[i + 1].type)) {
          this.parentAccounts[i].isChild = 1
        }
      }
    },
    async deleteExpense(item) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          let data
          if (item.type_number !== 4) {
            data = await CompaniesService.deleteExpenseTransaction({
              transaction_id: item.id,
              deleted_by: this.currentUser.user_id,
            })
          } else {
            data = await CompaniesService.deleteBankTransfer({
              transaction_id: item.id,
              deleted_by: this.currentUser.user_id,
              doc_no: item.doc_no,
              company_id: item.company_id,
            })
          }
          if (data.status === 200) {
            this.$refs.expensesTable.refresh()
            this.showSuccessSwal('Success', 'Expense deleted successfully!')
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    openBillTranModal() {
      this.openBillTranModalController = true
    },
    closeBillTranModal() {
      this.openBillTranModalController = false
    },
    closeListVendorsTranModal() {
      this.openListVendorsModalController = false
    },
    openListVendorsTranModal() {
      this.openListVendorsModalController = true
    },
    openTransactionModal(number, item) {
      // eslint-disable-next-line default-case
      switch (number) {
        case 1: this.expenseModalCont = true; this.idToSend = item.id; this.itemToSend = item; break
        case 2: this.checkModalCont = true; this.idToSend = item.id; this.itemToSend = item; break
        case 3: this.billModalCont = true; this.idToSend = item.id; this.itemToSend = item; break
      }
    },
    async exportPdfCheck(dataCheck){
      try {
        this.addPreloader();
        const data = await CompaniesService.exportPdfCheck({
          dataCheck: dataCheck,
        });
        if (data.status === 200) {
          window.open(data.data)
          this.showSuccessSwal('Success', 'Exporting successfully!')
        }
        this.removePreloader();
      } catch (e) {
        this.showErrorSwal(e)
        this.removePreloader();
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
    }),
  },
}
</script>

<style scoped>

</style>
