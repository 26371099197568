<template>
  <b-modal
      id="modal-closing"
      ref="modal"
      v-model="onControl"
      :header-bg-variant=" isDarkSkin?'dark' : 'light'"
      modal-class="tree-modal modal-primary transaction-modal"
      body-class="p-0"
      title-tag="h3"
      title-class="h3 text-dark font-weight-bolder"
      footer-bg-variant="dark"
      scrollable
      hide-header
      @hidden="close"
  >
    <b-row
        style="width: 100vw !important"
        class="m-0 px-1 pt-2"
        :class="isDarkSkin? 'bg-dark' : 'bg-light'"
    >
      <div
          style="width: 100% !important"
          class="d-flex justify-content-between"
      >
        <h2 class="d-inline text-dark font-weight-bolder mb-2 ml-1">
          BILL {{ bill.doc_no ? "no." + bill.doc_no : "" }}
        </h2>
        <div
            style="background: white !important;border: none; width: 30px !important; height: 30px !important;"
            class=" bg-white py-0 cursor-pointer rounded close-but"
            @click="close()"
        >
          <feather-icon
              class="text-primary"
              style="margin: 5px !important"
              icon="XIcon"
              size="18"
          />
        </div>
      </div>
      <b-row
          class="m-0 mt-1 mb-1"
          style="width: 100% !important"
      >
        <b-col md="9">
          <b-row>
            <b-col md="3">
                <span class="d-flex justify-content-between mb-half">
                  <span>Supplier</span>
                  <feather-icon
                      icon="PlusCircleIcon"
                      size="22"
                      class="text-primary cursor-pointer"
                      @click="vendorModalController=true"
                  />
                </span>
              <v-select
                  v-model="bill.payee"
                  class="bg-white"
                  placeholder="Select option"
                  :options="vendors"
                  label="company"
                  :disabled="isConfirmed"
                  @input="onChangeVendor"
              >
                <template #selected-option="{ print_on_check_as, display_name_as, company}">
                  <span>{{ print_on_check_as ? display_name_as : company }}</span>
                </template>
                <template #option="{ print_on_check_as, display_name_as, company}">
                  <span>{{ print_on_check_as ? display_name_as : company }}</span>
                </template>
              </v-select>
            </b-col>
            <b-col md="3">
                <span class="d-flex justify-content-between mb-half">
                  <span>Terms</span>
                  <feather-icon
                      icon="PlusCircleIcon"
                      size="22"
                      class="text-primary cursor-pointer"
                      @click="termsModalController = true"
                  />
                </span>
              <v-select
                  v-model="bill.term_id"
                  class="bg-white"
                  :reduce="name => name.id"
                  :options="terms"
                  label="name"
                  :disabled="isConfirmed"
              />
            </b-col>
            <b-col md="2">
              <b-form-group
                  label="Currency"
              >
                <b-form-select
                    v-model="bill.currency"
                    :class="isDarkSkin? '': 'bg-white'"
                    :options="currencies"
                    :disabled="isConfirmed || isCompanyFromUsa"
                    @input="updateBalance"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
                <span class="d-flex justify-content-between mb-half">
                  <span>Mailing address</span>
                </span>
              <b-form-textarea
                  v-model="bill.mailling_address"
                  style="height: inherit"
                  no-resize
                  :disabled="isConfirmed"
                  type="email"
              />
            </b-col>
            <b-col md="3">
                <span class="d-flex justify-content-between mb-half">
                  <span>Bill date</span>
                </span>
              <b-form-datepicker
                  v-model="bill.payment_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="en"
                  :disabled="isConfirmed"
              />
            </b-col>
            <b-col md="3">
                <span class="d-flex justify-content-between mb-half">
                  <span>Due date</span>
                </span>
              <b-form-datepicker
                  v-model="bill.due_date"
                  placeholder=""
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="en"
                  :disabled="isConfirmed"
              />
            </b-col>
            <b-col md="2">
                <span class="d-flex justify-content-between mb-half">
                  <span>Bill no.</span>
                </span>
              <b-form-input
                  v-model="bill.doc_no"
                  :disabled="isConfirmed"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col md="3"
            class="mt-1"
        >
          <div class="text-right mb-1 font-weight-bolder">
            Balance Due
          </div>
          <money
              v-if="money"
              :value="totalAmount"
              class="input-money-style input-money-specific text-right text-secondary font-weight-bolder"
              style="height: 40px;font-size: 30pt; width: 100%;"
              disabled
              v-bind="money"
          />
        </b-col>
      </b-row>
    </b-row>

    <transaction-table-component
      :expense="bill"
      :parent-accounts="parentAccounts"
      :is-confirmed="isConfirmed"
      :money="money"
      :deleteds="deleteds"
      :is-loading="isLoading"
      :is-expense="false"
      :total-amount="totalAmount"
      @openModal="categoryModalController=true"
    />
    <b-row
      class="mt-2 ml-1 d-flex justify-content-between"
      style="width: 100%;height: 80px"
    >
      <b-col xl="5" lg="5" md="5" sm="12">
        <b-form-group
          label="Memo"
        >
          <b-form-textarea
            v-model="bill.memo"
            style="width: 100%; height: 275px"
            :disabled="isConfirmed"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="!isConfirmed"
        xl="7"
        lg="7" md="7"
        sm="12"
      >
        <b-col md="12">
          <b-row>
            <b-col md="6">
              <div class="mb-half">
                Attachments
              </div>
            </b-col>
            <b-col md="6">
              <div class="mb-half">
                Maximum size: 20MB
              </div>
            </b-col>
          </b-row>
          <drag-and-drop
            ref="file-manager"
            v-model="files"
            source="bill"
            :files-array="files"
            :files-to-show="bill.files"
            @delete-file="deleteFile"
          />
        </b-col>
      </b-col>
    </b-row>
    <template #modal-footer="{cancel}">
      <div
        style="width: 100%;"
        class="d-flex justify-content-between"
      >
        <div>
          <b-button
            class="mr-1"
            @click="cancel()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="bill.status == null"
            @click="clearData"
          >
            Clear
          </b-button>
        </div>
        <b-dropdown
          dropup
          variant="primary"
          split
          text="Save and Close"
          @click="upload"
        >
          <b-dropdown-item @click="upload(1)">
            Save and new
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
    <create-edit-vendor-modal
      v-if="vendorModalController"
      @close="vendorModalController = false"
      @reload="getVendors"
    />
    <create-category-com-modal
      v-if="categoryModalController"
      :company-id="idCompany"
      @close="categoryModalController = false"
      @reload="getParentAccounts"
    />
    <create-term-modal
      v-if="termsModalController"
      :company-id="idCompany"
      @close="termsModalController = false"
      @reload="getTerms"
    />
  </b-modal>
</template>

<script>
import CompaniesService from '@/views/management/views/companies-old/companies.service'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import CreateEditVendorModal from '@/views/management/views/companies-old/dashboard/expenses-module/modals/CreateEditVendorModal'
import CreateCategoryComModal from '@/views/management/views/companies-old/dashboard/categories-module/modals/CreateCategoryComModal'
import CreateTermModal from '@/views/management/views/companies-old/dashboard/expenses-module/modals/CreateTermModal'
import TransactionTableComponent from '@/views/management/views/companies-old/dashboard/expenses-module/components/TransactionTableComponent'
import CategoriesService from '@/views/management/views/categories/categories.service'
import dragAndDrop from '@/views/commons/utilities/DragAndDrop'
import moment from 'moment'

export default {
  components: {
    vSelect,
    CreateEditVendorModal,
    CreateCategoryComModal,
    CreateTermModal,
    TransactionTableComponent,
    dragAndDrop,
  },
  props: {
    transactionId: null,
    transactionItem: null,
  },
  data() {
    return {
      onControl: false,
      idCompany: '',
      idDrag: 1,
      files: [],
      termsModalController: false,
      categoryModalController: false,
      vendors: [],
      deleteds: [],
      parentAccounts: [],
      vendorModalController: false,
      bill: {
        transaction_id: null,
        company_id: '',
        payee: null,
        payment_account: null,
        bank_account_dest_id: null,
        payment_date: null,
        due_date: null,
        payment_method: null,
        term_id: null,
        doc_no: null,
        mailling_address: null,
        exchange: null,
        memo: null,
        type: 3,
        status: null,
        currency: null,
        previous_total: null,
        is_intercompany_transfer: false,
        detail: [
          {
            category: null,
            description: null,
            amount: 0,
            id: null,
            identifier: null,
          },
          {
            category: null,
            description: null,
            amount: 0,
            id: null,
            identifier: null,
          },
        ],
        files: null,
      },
      currencies: [
        {
          value: 1,
          text: 'S/',
        },
        {
          value: 2,
          text: '$',
        },
      ],
      terms: [],
      deletedFiles: [],
      country: '',
      bankAccounts: [],
      balance: 0,
      currentBankAccount: {},
      isLoading: '',
    }
  },
  async created() {
    this.onControl = true
    this.isLoading = true
    this.idCompany = this.$route.params.id
    this.bill.company_id = this.idCompany
    this.bill.payment_date = moment().format()
    this.addPreloader()
    const data = await CompaniesService.getCompanyCountryById({
      company_id: this.idCompany,
    })
    const { country } = data.data[0]
    this.country = country
    this.bill.currency = country === 'US' ? 2 : 1
    await this.getTerms()
    await this.getBankAccounts()
    await this.getVendors()
    await this.getParentAccounts()
    this.updateBalance()
    this.removePreloader()
    this.isLoading = false
  },
  methods: {
    close() {
      this.$emit('close')
    },
    updateBalance() {
      this.bankAccounts.forEach(account => {
        if (account.currency === this.bill.currency)
        {
          this.currentBankAccount = account
          this.balance = this.calculateNewBalance(account.balance, account.total_incomes, account.total_expenses)
        }
      })
    },
    clearData() {
      this.bill.transaction_id = null
      this.bill.company_id = this.idCompany
      this.bill.payee = null
      this.bill.payment_account = null
      this.bill.payment_date = moment().format()
      this.bill.payment_method = null
      this.bill.doc_no = null
      this.bill.memo = null
      this.bill.mailling_address = null
      this.bill.type = 3
      this.bill.status = null
      this.bill.due_date = null
      this.bill.previous_total = null
      this.$set(this.bill, 'detail', [
        {
          category: null,
          description: null,
          amount: 0,
          id: null,
          identifier: null,
        },
        {
          category: null,
          description: null,
          amount: 0,
          id: null,
          identifier: null,
        },
      ])
      this.bill.files = null
      this.bill.term_id = null
      this.files = []
      this.$refs['file-manager'].removeAllFiles()
      this.images = []
    },
    deleteFile(index, file) {
      this.bill.files.splice(index, 1)
      this.deletedFiles.push(file.id)
    },
    async upload(stat) {
      if (this.balance >= this.totalAmount) {
        if (this.isAtLeastOneItemAdded) {
          try {
            const response = await this.showConfirmSwal()
            if (response.isConfirmed) {
              const formData = new FormData()
              this.files.forEach(file => {
                formData.append('images[]', file, file.name)
              })
              this.addPreloader()

              formData.append('header', JSON.stringify(this.bill))
              formData.append('created_by', this.currentUser.user_id)
              formData.append('status', 1)
              formData.append('total', this.totalAmount)
              const headers = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                // eslint-disable-next-line func-names
                onUploadProgress: function (progressEvent) {
                  this.uploadPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total,
                  )
                }.bind(this),
              }
              const data = await CompaniesService.saveTransactionExpense(formData, headers)
              if (data.status === 200) {
                if (stat !== 1) {
                  this.removePreloader()
                  this.$emit('reload')
                  this.close()
                  this.showSuccessSwal()
                } else {
                  this.removePreloader()
                  this.clearData()
                }
                if (this.deleteds.length > 0) {
                  await CompaniesService.deleteTransactionDetail({
                    deleteds: this.deleteds,
                  })
                }
                if (this.deletedFiles.length > 0) {
                  await CompaniesService.deleteTransactionFile({
                    deleted_files: this.deletedFiles,
                  })
                }
              }
            }
          } catch (e) {
            this.showErrorSwal(e)
            this.removePreloader()
          }
        } else {
          this.showWarningSwal('Important!', 'Please enter at least one line item.')
        }
      } else {
        this.showWarningSwal('Important!', 'Total amount of this bill can not be greater than the balance of the bank account: '+ this.currentBankAccount.bank_name + ' ' + this.currentBankAccount.currency_text
        + this.balance)
      }
    },
    async getBankAccounts() {
      try {
        const data = await CompaniesService.getAllManBankAccounts({
          idcompany: this.$route.params.id,
        })
        this.bankAccounts = data.data
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async getVendors() {
      try {
        const data = await CompaniesService.getAllVendors({
          idcompany: this.idCompany,
        })
        this.vendors = data.data
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async getParentAccounts() {
      const data = await CategoriesService.getParentAccounts({
        company_id: this.idCompany,
      })
      this.parentAccounts = data

      for (let i = 0; i < this.parentAccounts.length - 1; i++) {
        if ((this.parentAccounts[i].type < this.parentAccounts[i + 1].type)) {
          this.parentAccounts[i].isChild = 1
        }
      }
      if (this.transactionId != null) {
        await this.getTransaction()
        // for (let i = 0; i < this.parentAccounts.length; i++) {
        //   for (let k = 0; k < this.bill.detail.length; k++) {
        //     if (this.parentAccounts[i].id == this.bill.detail[k].category) {
        //       this.bill.detail[k].category = this.parentAccounts[i]
        //       break
        //     }
        //   }
        // }
      }
    },
    async getTransaction() {
      try {
        const data = await CompaniesService.getTransactionExpense({
          transaction_id: this.transactionId,
        })
        if (data.status === 200) {
          this.bill = data.data[0]
        }
        for (let i = 0; i < this.vendors.length; i++) {
          if (this.vendors[i].id == this.bill.payee) {
            this.bill.payee = this.vendors[i]
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    calculateNewBalance(balance, incomes, expenses) {
      return Number(balance) + Number(incomes) - Number(expenses)
    },
    async getTerms() {
      try {
        const data = await CompaniesService.getTerms({
          company_id: this.idCompany,
        })
        if (data.status === 200) {
          this.terms = data.data
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    onChangeVendor() {
      const id = this.vendors.findIndex(vendor => vendor.id === this.bill.payee.id)
      if (!this.vendors[id].address
          && !this.vendors[id].city
          && !this.vendors[id].state
          && !this.vendors[id].zip_code) {
        this.bill.mailling_address = null
      } else {
        this.bill.mailling_address = `${this.vendors[id].address ? this.vendors[id].address : ''} ${this.vendors[id].city ? this.vendors[id].city : ''} ${this.vendors[id].state ? this.vendors[id].state : ''}
        ${this.vendors[id].zip_code ? this.vendors[id].zip_code : ''}`
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    isConfirmed() {
      return this.bill.status == 2
    },
    isAtLeastOneItemAdded() {
      for (let i = 0; i < this.bill.detail.length; i++) {
        if (this.bill.detail[i].category != null || this.bill.detail[i].description || this.bill.detail[i].amount != 0) {
          return true
        }
      }
      return false
    },
    money() {
      for (let i = 0; i < this.currencies.length; i++) {
        if (this.currencies[i].value == this.bill.currency) {
          return {
            decimal: '.',
            thousands: ',',
            prefix: this.currencies[i].text,
            precision: 2,
            masked: false,
          }
        }
      }
    },
    totalAmount() {
      let sum = 0
      for (let i = 0; i < this.bill.detail.length; i++) {
        sum += Number(this.bill.detail[i].amount)
      }
      return sum.toFixed(2)
    },
    isCompanyFromUsa() {
      return this.country == 'US'
    },
  },
}
</script>
<style>
.tree-modal .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: calc(100vh);
  display: flex;
  position: fixed;
  z-index: 100000;
}
@media (min-width: 576px){
  .transaction-modal .modal-dialog-scrollable .modal-content{
    max-height: calc(100vh);
  }
}
</style>
<style scoped>
.input-money-style{
  width: 100%; height: 2.714rem; border: transparent;
}
</style>
