<template>
  <b-modal
    v-model="ownControl"
    scrollable
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    :title="title"
    size="caro"
    @hidden="closeModal"
  >
    <div>
      <validation-observer ref="form">
        <vendor-costumer-component
          :vendor="vendor"
          :real-display-name="display_name_as_sec"
          :finished-loading="finishedLoading"
        />
        <b-row>
          <billing-address-component
            :mood="2"
            :vendor="vendor"
            :finished-loading="finishedLoading"
            :is-company-from-usa="isCompanyFromUsa"
          />
          <b-col md="6">
            <b-row>
              <b-col md="12">
                <b-form-group
                  id="No."
                  label="Business ID No./ Social Security No."
                  label-for="No."
                >
                  <b-skeleton v-if="!finishedLoading" type="input" />
                  <b-form-input
                    v-else
                    id="No.-bus"
                    v-model="vendor.business_id"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col md="6">
                <b-form-checkbox
                  id="track_payments"
                  v-model="vendor.track_payments_for"
                  name="checkbox-1"
                >
                  <span class="font-small-3">Track payments for 1099</span>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-form-group label="Choose parent account:" label-for="input-13">
              <b-skeleton v-if="!finishedLoading" type="input" />
              <v-select
                v-else
                v-model="vendor.default_expense_account"
                label="name"
                :options="parentAccounts"
              >
                <template #selected-option="{ name, parent_name, type }">
                  <span v-if="type === 1">{{ name }}</span>
                  <span v-else>{{ parent_name + " : " + name }}</span>
                </template>
                <template
                  #option="{
                    name,
                    id,
                    parent_account,
                    type,
                    isChild,
                    account_type_text,
                  }"
                >
                  <span class="d-flex justify-content-between">
                    <span
                      :style="`margin-left: ${
                        id == parent_account ? 0 : type * 15
                      }px`"
                    >
                      <feather-icon
                        v-if="isChild === 1 || id == parent_account"
                        icon="ChevronDownIcon"
                        size="10"
                      />
                      <span v-else>&nbsp;&nbsp;&nbsp;&nbsp; </span>
                      {{ name }}</span
                    >
                    <span v-if="id == parent_account">{{
                      account_type_text
                    }}</span>
                  </span>
                </template>
              </v-select>
            </b-form-group>
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="6">
                    <div class="mb-1">Attachments</div>
                  </b-col>
                  <b-col md="6">
                    <div class="mb-1">Maximum size: 20MB</div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <drag-and-drop
                      v-model="files2"
                      source="vendor"
                      :files-array="files2"
                      :files-to-show="vendor.files"
                      @delete-file="deleteFile"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
    <template #modal-footer>
      <button-save @click="uploadFiles" />
    </template>
  </b-modal>
</template>

<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import VendorCostumerComponent from "@/views/management/views/companies-old/dashboard/components/VendorCostumerComponent.vue";
import BillingAddressComponent from "@/views/management/views/companies-old/dashboard/components/BillingAddressComponent.vue";
import { mapGetters } from "vuex";
import CompaniesService from "@/views/management/views/companies-old/companies.service";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import CategoriesService from "@/views/management/views/categories/categories.service";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    DragAndDrop,
    VendorCostumerComponent,
    BillingAddressComponent,
    ButtonSave,
  },
  props: {
    vendorProp: null,
  },
  data() {
    return {
      spinnerOn: false,
      idDrag: 1,
      files2: [],
      parentAccounts: [],
      ownControl: false,
      idCompany: 0,
      display_name_as_sec: "",
      finishedLoading: true,
      title: "CREATE VENDOR",
      isCompanyFromUsa: false,
      vendor: {
        vendor_id: null,
        idcompany: "",
        title: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        suffix: "",
        emails: "",
        company: "",
        display_name_as: "",
        phone: "",
        mobile: "",
        other: "",
        website: "",
        fax: "",
        address: "",
        city: "",
        state: "",
        zip_code: "",
        note: "",
        business_id: "",
        country: "",
        print_on_check_as: true,
        track_payments_for: false,
        default_expense_account: "",
        files: null,
      },
      deletedFiles: [],
    };
  },
  async created() {
    this.ownControl = true;
    this.idCompany = this.$route.params.id;
    this.vendor.idcompany = this.idCompany;
    if (this.vendorProp != null) {
      this.title = "EDIT VENDOR";
      this.finishedLoading = false;
    }
    await this.getParentAccounts();
    const data = await CompaniesService.getCompanyCountryById({
      company_id: this.idCompany,
    });
    const { country } = data.data[0];
    if (country === "US") this.isCompanyFromUsa = true;
    if (this.vendorProp != null) {
      await this.getVendor();
      this.finishedLoading = true;
      if (this.vendor.display_name_as === null)
        this.vendor.display_name_as = this.display_name_as_sec;
    } else {
      await this.getCountry();
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async uploadFiles() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            const formData = new FormData();
            if (this.files2 !== null && this.files2 !== "") {
              this.files2.forEach((file) => {
                formData.append("images[]", file, file.name);
              });
            }
            this.addPreloader();
            this.vendor.zip_code === null
              ? this.vendor.zip_code
              : (this.vendor.zip_code = null);
            this.vendor.track_payments_for = Number(
              this.vendor.track_payments_for
            );
            this.vendor.print_on_check_as = Number(
              this.vendor.print_on_check_as
            );
            formData.append("header", JSON.stringify(this.vendor));
            formData.append("created_by", this.currentUser.user_id);
            formData.append("updated_by", this.currentUser.user_id);
            const headers = {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              // eslint-disable-next-line func-names
              onUploadProgress: function (progressEvent) {
                this.uploadPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              }.bind(this),
            };
            const data = await CompaniesService.saveVendor(formData, headers);
            if (data.status === 200) {
              this.files2 = [];
              this.idDrag++;
              this.closeModal();
              this.$emit("reload");
              this.showSuccessSwal();
              if (this.deletedFiles.length > 0) {
                const data = await CompaniesService.deleteFilesVendor({
                  deleted_files: this.deletedFiles,
                });
              }
            }
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },
    async getParentAccounts() {
      const data = await CategoriesService.getParentAccounts({
        company_id: this.idCompany,
      });
      this.parentAccounts = data;

      for (let i = 0; i < this.parentAccounts.length - 1; i++) {
        if (this.parentAccounts[i].type < this.parentAccounts[i + 1].type) {
          this.parentAccounts[i].isChild = 1;
        }
      }
    },
    async getCountry() {
      const data = await CompaniesService.getCompanyCountryById({
        company_id: this.idCompany,
      });
      this.vendor.country = data.data[0].country;
    },
    async getVendor() {
      try {
        const data = await CompaniesService.getVendor({
          vendor_id: this.vendorProp,
        });
        this.vendor = data.data[0];
        this.display_name_as_sec = this.vendor.display_name_as;
        const id = this.vendor.default_expense_account;
        this.vendor.track_payments_for = Boolean(
          this.vendor.track_payments_for
        );
        this.vendor.print_on_check_as = Boolean(this.vendor.print_on_check_as);
        for (let i = 0; i < this.parentAccounts.length; i++) {
          if (this.parentAccounts[i].id === id) {
            this.vendor.default_expense_account = this.parentAccounts[i];
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    deleteFile(index, file) {
      this.vendor.files.splice(index, 1);
      this.deletedFiles.push(file.id);
    },
  },
};
</script>

<style scoped>
</style>
