<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['bankTransferTable'].refresh()"
      @reset-all-filters="resetAllFilters"
    >
      <template #table>
        <b-table
          ref="bankTransferTable"
          no-border-collapse
          class="position-relative font-small-3"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="bankTransfersProvider"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(payment_date)="data">
            {{ data.item.payment_date | myGlobal }}
          </template>
          <template #cell(bank_name)="data">
            <span
              v-b-tooltip.hover.bottom="bankHover(data.item.origin_business_name, data.item.origin_currency_text)"
              class="pt-2"
            >{{ data.item.bank_name }}</span>
          </template>
          <template #cell(destination_bank_name)="data">
            <span
              v-b-tooltip.hover.bottom="destinationBankHover(data.item.destination_business_name, data.item.destination_currency_text)"
              class="pt-2"
            >{{ data.item.destination_bank_name }}</span>
          </template>
          <template #cell(total)="data">
            <money
              :value="data.item.total"
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: data.item.currency,
                precision: 2,
                masked: false,
              }"
              style="border: none"
              disabled
            />
          </template>
          <template #cell(is_intercompany_transfer)="data">
            {{ data.item.is_intercompany_transfer?'INTER-COMPANY':'INTER-BANK' }}
          </template>
          <template #cell(created_by)="data">
            {{ data.item.first_name }} {{ data.item.last_name }} <br>
            {{ data.item.created_at | myGlobalDay }}
          </template>
          <template #cell(actions)="data">
            <feather-icon
              v-if="isCeo"
              size="18"
              class="cursor-pointer text-danger"
              icon="TrashIcon"
              @click="deleteBankTransfer(data.item)"
            />
          </template>
        </b-table>
        <transition name="slide-fade">
          <create-bill-transaction-modal
            v-if="openBillTranModalController"
            @close="closeBillTranModal"
          />
        </transition>
        <list-vendors-modal
          v-if="openListVendorsModalController"
          @close="closeListVendorsTranModal"
        />
      </template>
    </filter-slot>
  </div>
</template>

<script>
import CompaniesService from '@/views/management/views/companies/companies.service'
import CreateBillTransactionModal from '@/views/management/views/companies/dashboard/expenses-module/modals/CreateBillTransactionModal'
import ListVendorsModal from '@/views/management/views/companies/dashboard/expenses-module/modals/ListVendorsModal'
import bankTransfersTableFilters from '@/views/management/views/companies/dashboard/expenses-module/bank-transfers-table.filters'
import { mapGetters } from 'vuex'

export default {
  components: {
    CreateBillTransactionModal,
    ListVendorsModal,
  },
  data() {
    return {
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      searchInput: '',
      show: false,
      fields: [
        {
          key: 'payment_date',
          label: 'DATE',
        },
        {
          key: 'type',
          label: 'TYPE',
        },
        {
          key: 'doc_no',
          label: 'NO.',
        },
        {
          key: 'bank_name',
          label: 'Origin bank',
        },
        {
          key: 'destination_bank_name',
          label: 'Destination bank',
        },
        {
          key: 'memo',
          label: 'MEMO',
        },
        {
          key: 'total',
          label: 'TOTAL',
        },
        {
          key: 'is_intercompany_transfer',
          label: 'Type',
        },
        {
          key: 'created_by',
          label: 'CREATED BY',
          thStyle: {
            width: '180px',
          },
        },
        {
          key: 'actions',
          label: 'ACTIONS',
        },
      ],
      isBusy: false,
      transactions: [],
      idCompany: '',
      openBillTranModalController: false,
      openListVendorsModalController: false,
      filters: bankTransfersTableFilters,
    }
  },
  async created() {
    this.idCompany = this.$route.params.id
  },
  methods: {
    async bankTransfersProvider(ctx) {
      try {
        const data = await CompaniesService.getBankTransfers({
          orderby: 1,
          order: 'desc',
          campo: this.filterPrincipal.model,
          company_id: Number(this.idCompany),
          perpage: ctx.perPage,
          page: ctx.currentPage,
          from: this.filters[0].model,
          to: this.filters[1].model,
        })
        if (data.status === 200) {
          this.transactions = data.data.data
          this.startPage = data.data.from? data.data.from : 0
          this.paginate.perPage = data.data.per_page
          this.paginate.currentPage = data.data.current_page
          this.endPage = data.data.last_page
          this.totalRows = data.data.total
          this.toPage = data.data.to ? data.data.to:0
          return this.transactions || []
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },

    resetAllFilters() {
      this.filters.forEach(filter => {
        filter.model = null
      })
      this.filterPrincipal.model = null
      this.$refs.bankTransferTable.refresh()
    },
    bankHover(originBusinessName, originCurrencyText) {
      return `Bank: ${originBusinessName} - Currency ${originCurrencyText}`
    },
    destinationBankHover(destinationBusinessName, destinationCurrencyText) {
      return `Bank: ${destinationBusinessName} - Currency ${destinationCurrencyText}`
    },
    async deleteExpense(item) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          let data
          if (item.type_number !== 4) {
            data = await CompaniesService.deleteExpenseTransaction({
              transaction_id: item.id,
              deleted_by: this.currentUser.user_id,
            })
          } else {
            data = await CompaniesService.deleteBankTransfer({
              transaction_id: item.id,
              deleted_by: this.currentUser.user_id,
              doc_no: item.doc_no,
              company_id: item.company_id,
            })
          }
          if (data.status === 200) {
            this.$refs.bankTransferTable.refresh()
            this.showSuccessSwal('Success', 'Expense deleted successfully!')
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    openBillTranModal() {
      this.openBillTranModalController = true
    },
    closeBillTranModal() {
      this.openBillTranModalController = false
    },
    closeListVendorsTranModal() {
      this.openListVendorsModalController = false
    },
    openListVendorsTranModal() {
      this.openListVendorsModalController = true
    },
    async deleteBankTransfer(item) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const data = await CompaniesService.deleteBankTransfer({
            transaction_id: item.id,
            deleted_by: this.currentUser.user_id,
            doc_no: item.doc_no,
            company_id: item.is_intercompany_transfer
              ? item.destination_company_id
              : item.company_id,
          })
          if (data.status === 200) {
            this.$refs.bankTransferTable.refresh()
            this.showSuccessSwal('Success', 'Bank Transfer deleted successfully!')
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
    }),
  },
}
</script>

<style scoped>

</style>
