<template>
  <div>
    <validation-observer ref="form">
      <b-row>
        <b-col md="6">
          <b-row>
            <b-col
              md="2"
              class=" p-half pla-1"
            >
              <b-form-group
                id="title-1"
                label="Title"
                label-for="title-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="title-1"
                  v-model="vendor.title"
                  trim
                />
              </b-form-group>

            </b-col>
            <b-col
              md="3"
              class="p-0 p-half"
            >
              <b-form-group
                id="first_name-1"
                label="First name"
                label-for="first_name-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="first_name-1"
                  v-model="vendor.first_name"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              class="p-0 p-half"
            >
              <b-form-group
                id="middle_name-1"
                label="Middle name"
                label-for="middle_name"
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="middle_name"
                  v-model="vendor.middle_name"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              class="p-0 p-half"
            >
              <b-form-group
                id="last_name-1"
                label="Last name"
                label-for="last_name"
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="last_name"
                  v-model="vendor.last_name"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              class="p-0 p-half pra-1"
            >
              <b-form-group
                id="suffix-1"
                label="Suffix"
                label-for="suffix"
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="suffix"
                  v-model="vendor.suffix"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <validation-provider
            v-slot="{ errors,valid }"
            name="emailss"
            rules="email"
          >
            <b-form-group
              id="emails-1"
              label="Email"
              label-for="emails"
            >
              <b-skeleton
                v-if="!finishedLoading"
                type="input"
              />
              <b-form-input
                v-else
                :state="errors[0] ? false : valid ? true : null"
                id="emails"
                v-model="vendor.emails"
                trim
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            id="company-1"
            label="Company"
            label-for="company"
          >
            <b-skeleton
              v-if="!finishedLoading"
              type="input"
            />
            <b-form-input
              v-else
              id="company"
              v-model="vendor.company"
              trim
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="4">
              <validation-provider
                v-slot="{ errors,valid }"
                name="phone"
                rules="min:14"
              >
                <b-form-group
                  id="phone-1"
                  label="Phone"
                  label-for="phone-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"
                    type="input"
                  />
                  <b-form-input
                    v-else
                    id="phone-1"
                    v-model="vendor.phone"
                    v-mask="'(###)-###-####'"
                    :state="errors[0] ? false : valid ? true : null"
                    maxlength="14"
                    trim
                    @keyup.native="phone()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                v-slot="{ errors,valid }"
                name="phone"
                rules="min:14"
              >
                <b-form-group
                  id="mobile-1"
                  label="Mobile"
                  label-for="mobile-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"
                    type="input"
                  />
                  <b-form-input
                    v-else
                    id="mobile-1"
                    v-model="vendor.mobile"
                    v-mask="'(###)-###-####'"
                    maxlength="14"
                    :state="errors[0] ? false : valid ? true : null"
                    trim
                    @keyup.native="mobile()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-form-group
                id="fax-1"
                label="Fax"
                label-for="fax-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="fax-1"
                  v-model="vendor.fax"
                  v-mask="'(###)-###-####'"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <validation-provider
            v-slot="{ errors,valid }"
            name="display_name_as"
            rules="required"
          >
            <b-form-group
              id="display_name_as-1"
              label="Display name as"
              label-for="display_name_as-1"
            >
              <b-skeleton
                v-if="!finishedLoading"
                type="input"
              />
              <b-form-input
                v-else
                id="display_name_as-1"
                v-model="vendor.display_name_as"
                :state="errors[0] ? false : valid ? true : null"
                trim
                disabled
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="4">
              <b-form-group
                id="other-1"
                label="Other"
                label-for="other-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="other-1"
                  v-model="vendor.other"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group
                id="website-1"
                label="Website"
                label-for="website-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="website-1"
                  v-model="vendor.website"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="auto">
          <b-row>
            <b-col md="auto">
              <div class="mb-1">
                <span class="font-small-3">Print on check as</span>
              </div>
            </b-col>
            <b-col md="auto">
              <b-form-checkbox
                id="print_on_check_as-1"
                v-model="vendor.print_on_check_as"
                name="checkbox-1"
              >
                <span class="font-small-3">Use display name</span>
              </b-form-checkbox>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
export default {
  props: {
    vendor: { type: Object },
    realDisplayName: '',
    finishedLoading: null,
  },
  data() {
    return {
      cont: 0,
    }
  },
  watch: {
    'vendor.company': function (newVal, oldVal) {
      if (this.vendor.company != null) {
        this.vendor.display_name_as = null
        this.vendor.display_name_as = newVal
      }
    },
    'vendor.first_name': function (newVal) {
      if (!this.vendor.company && this.vendor.first_name != null) {
        this.vendor.display_name_as = null
        this.vendor.display_name_as = `${newVal} ${
          this.vendor.middle_name ? this.vendor.middle_name : ''
        } ${this.vendor.last_name ? this.vendor.last_name : ''}`
      }
    },
    'vendor.middle_name': function (newVal) {
      if (!this.vendor.company && this.vendor.middle_name != null) {
        this.vendor.display_name_as = null
        this.vendor.display_name_as = `${
          this.vendor.first_name ? this.vendor.first_name : ''
        } ${newVal} ${
          this.vendor.last_name ? this.vendor.last_name : ''
        }`
      }
    },
    'vendor.last_name': function (newVal) {
      if (!this.vendor.company && this.vendor.last_name != null) {
        this.vendor.display_name_as = null
        this.vendor.display_name_as = `${
          this.vendor.first_name ? this.vendor.first_name : ''
        } ${
          this.vendor.middle_name ? this.vendor.middle_name : ''
        } ${newVal}`
      }
    },
  },
  methods: {
    phone() {
      const x = this.vendor.phone
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.vendor.phone = !x[2]
        ? x[1]
        : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`
    },
    mobile() {
      const x = this.vendor.mobile
          .replace(/\D/g, '')
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.vendor.mobile = !x[2]
          ? x[1]
          : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`
    },
  },
}
</script>

<style scoped>
.p-half{
  padding: 0.2rem !important;
}
.pla-1{
  padding-left: 1rem !important;
}
.pra-1{
  padding-right: 1rem !important;
}
</style>
